@import '../../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .video-category-item-wrapper {
    width: 340px;
    max-width: 1089px;
    margin: auto;
    margin-bottom: 100px;
    .header-video-category-item-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      p {
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-bottom: 0px;
        color: #000000;
      }
      button {
        background: none;
        outline: none;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 11px;
        color: #000000;
        font-weight: 100;
      }
    }
    .body-video-category-item-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {

  .video-category-item-wrapper {
    width: 79.65vw;
    margin: auto;
    margin-bottom: 4.653vw;
    .header-video-category-item-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.389vw;
      p {
        font-size: 1.389vw;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-bottom: 0px;
        color: #000000;
      }
      button {
        background: none;
        outline: none;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 1.042vw;
        color: #000000;
        font-weight: 100;
      }
    }
    .body-video-category-item-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  .video-category-item-wrapper {
    width: 1089px;
    max-width: 1089px;
    margin-bottom: 67px;
    .header-video-category-item-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      p {
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-bottom: 0px;
        color: #000000;
      }
      button {
        background: none;
        outline: none;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        color: #000000;
        font-weight: 100;
      }
    }
    .body-video-category-item-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}