// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

#container-wrap {
  padding: 0 140px;
  @media only screen and (max-width: $smallMobileBreakPoint) {
    padding: 0 18px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
    padding: 0px 18px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}