@import '../../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  #english-course-combo-wrapper {
    margin-top: 12px;
    #list-english-course-combo-wrapper {
      padding: 0 25px;
      h5 {
        font-size: 10px;
        font-family: Poppins;
        font-weight: bold;
        color: #707070;
      }
      .ant-list {
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-list-items {
              .item-include-wrapper {
                height: 40px;
                background-color: $gold;
                color: #FFFFFF;
                margin-bottom: 12px;
                font-size: 10px;
                font-family: 'Poppins';
                display: flex;
                align-items: center;
                padding-left: 10px;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $mobileBreakPoint) {
  #english-course-combo-wrapper {
    margin-top: 12px;
    #list-english-course-combo-wrapper {
      padding: 0 25px;
      h5 {
        font-size: 10px;
        font-family: Poppins;
        font-weight: bold;
        color: #707070;
      }
      .ant-list {
        width: 80%;
        margin: auto;
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-list-items {
              .item-include-wrapper {
                height: 40px;
                background-color: $gold;
                color: #FFFFFF;
                margin-bottom: 12px;
                font-size: 12px;
                font-family: 'Poppins';
                display: flex;
                align-items: center;
                padding-left: 10px;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $mobileBreakPoint + 1) {
  #english-course-combo-wrapper {
    margin-top: 14px;
    #list-english-course-combo-wrapper {
      h5 {
        font-size: 12px;
        font-family: Poppins;
        font-weight: bold;
        color: #707070;
      }
      .ant-list {
        .ant-spin-nested-loading {
          .ant-spin-container {
            .ant-list-items {
              .item-include-wrapper {
                height: 30px;
                background-color: $gold;
                color: #FFFFFF;
                margin-bottom: 12px;
                font-size: 12px;
                font-family: 'Poppins';
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}