@import '../../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .speaker-card-wrapper {
    width: 167px;
    height: 215px;
    border-radius: 10px;
    box-shadow: 2px 0px 5px rgba(#000000, 0.16);
    margin-bottom: 33px;
    .avatar-speaker-card-wrapper {
      width: 100%;
      height: 129px;
      .image-avatar-speaker-card-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .content-speaker-card-wrapper {
      width: 100%;
      padding: 5px 0px;
      text-align: center;
      .header-content-speaker-card-wrapper {
        font-size: 12px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        color: $brown;
        margin-bottom: 5px;
      }
      .body-content-speaker-card-wrapper {
        width: 108px;
        height: 11px;
        overflow: hidden;
        margin: auto;
        font-size: 8px;
        color: $grey;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-bottom: 16px;
      }
      .footer-content-speaker-card-wrapper {
        width: 72px;
        height: 19px;
        border-radius: 10px;
        background-color: $gold;
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 6px;
        border: none;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  .speaker-card-wrapper {
    width: 24.444vw;
    height: 28.75vw;
    border-radius: 10px;
    box-shadow: 2px 0px 5px rgba(#000000, 0.16);
    margin: auto;
    margin-bottom: 5.278vw;
    .avatar-speaker-card-wrapper {
      width: 100%;
      height: 18.958vw;
      .image-avatar-speaker-card-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .content-speaker-card-wrapper {
      width: 100%;
      padding: 0.903vw 0px;
      text-align: center;
      .header-content-speaker-card-wrapper {
        font-size: 1.736vw;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        color: $brown;
        margin-bottom: 0.033vw;
      }
      .body-content-speaker-card-wrapper {
        width: 17.917vw;
        height: 1.667vw;
        overflow: hidden;
        margin: auto;
        font-size: 1.181vw;
        color: $grey;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-bottom: 0.483vw;
      }
      .footer-content-speaker-card-wrapper {
        width: 10.556vw;
        height: 2.847vw;
        border-radius: 10px;
        background-color: $gold;
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 0.972vw;
        border: none;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  .speaker-card-wrapper {
    width: 352px;
    height: 441px;
    border-radius: 10px;
    box-shadow: 2px 0px 5px rgba(#000000, 0.16);
    margin-bottom: 76px;
    .avatar-speaker-card-wrapper {
      width: 100%;
      height: 273px;
      .image-avatar-speaker-card-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .content-speaker-card-wrapper {
      width: 100%;
      padding: 13px 0px;
      text-align: center;
      .header-content-speaker-card-wrapper {
        font-size: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        color: $brown;
        margin-bottom: 12px;
      }
      .body-content-speaker-card-wrapper {
        width: 258px;
        height: 24px;
        overflow: hidden;
        margin: auto;
        font-size: 17px;
        color: $grey;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        margin-bottom: 30px;
      }
      .footer-content-speaker-card-wrapper {
        width: 152px;
        height: 41px;
        border-radius: 10px;
        background-color: $gold;
        color: #ffffff;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        border: none;
        outline: none;
      }
    }
  }
}