.container__total {
  display: flex;
  flex-direction: column;
  .cash__box {
    padding: 26px 100px;
    color: #3794fc;
    box-shadow: 0 5px 10px#00000040;
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .group__button {
    .btn__deposit {
      background: #b97a2a;
      padding: 10px 20px;
      color: #fff;
      border-radius: 10px;
      font-weight: bold;
      cursor: pointer;
    }
    .btn__history {
      background: #d6d6d6;
      padding: 10px 20px;
      color: #b97a2a;
      border-radius: 10px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

//custom table antd
.ant-table-thead > tr > th {
  background: #d59e29;
  color: #fff;
}

// .ant-table-container table > thead > tr:first-child th:first-child {
//   border-top-left-radius: 30px;
//   border-bottom-left-radius: 30px;
// }

// .ant-table-container table > thead > tr:first-child th:last-child {
//   border-top-right-radius: 30px;
//   border-bottom-right-radius: 30px;
// }
