$desktop: "(min-width: 960px)";
$mobile: "(min-width: 320px) and (max-width: 768px)";

@media screen and (min-width: 960px) {
  hr {
    border-bottom: 0.5px solid #dee5ef;
  }
  .container__capital {
    display: flex;
    justify-content: space-between;
    max-width: 998px;
    margin-left: auto;
    margin-right: auto;
    .left {
      width: 65%;
      .list__insurance {
        overflow-y: auto;
        margin-bottom: 60px;
        min-height: 350px;
      }
    }
    .right {
      width: 30%;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  hr {
    border-bottom: 0.5px solid #dee5ef;
  }
  .container__capital {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 998px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    .left {
      width: 65%;
      h4 {
        font-size: 20px;
      }
      .list__insurance {
        overflow-y: auto;
        margin-bottom: 60px;
        min-height: 350px;
      }
    }
    .right {
      width: 100%;
      h4 {
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
