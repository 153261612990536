@import '../../../shared.scss';

.form-control{
  padding-left: 22px;
  padding-right: 22px;
  height: 50px;
  width: 334px;
  margin-top: 6px;
  margin-bottom: 25px;
}

.btn-cta {
  background-color: #d59e29;
  color: white;
  width: 334px;
  padding: 11px 5px;
  font-size: 12pt;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
}
