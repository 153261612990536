@import "../../shared.scss";
@import "../../assets/font/font.scss";

.ChangeProfile {
  .Title {
    color: $darkblue;
    @extend %fontHeader;
    padding-bottom: 5px;
    border-bottom: 1px solid $lightgrey;

    @media only screen and (max-width: $mobileBreakPoint) {
      margin-bottom: 10px;
    }
    @media only screen and (min-width: $mobileBreakPoint + 1) {
      margin-bottom: 20px;
    }
  }
  .Profile {
    .Avatar {
      font-size: 10px;

      .ChangePhotoText {
        color: $grey;
        padding-left: 5px;
      }

      .ChangePhotoButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: white;
        font-style: italic;
        background-color: $gold;
        border-radius: 15px;
        padding: 2px 12px;
        border: 1px solid $gold;
        box-sizing: border-box;
        display: inline-flex;
        margin-top: 2px;

        img {
          padding-right: 5px;
        }
      }
    }
    .GroupProfile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: flex-end;
      .GroupProfile1,
      .GroupProfile2 {
        .Text {
          color: $lightgrey;
        }
      }

      .NewPassword {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      input,
      select {
        border: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        @extend %fontSemiBold;
        color: $darkgrey;
        margin-top: 5px;
        margin-bottom: 15px;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @extend %fontSemiBold;
    color: white;

    .CancelButton,
    .UpdateButton {
      background-color: $brown;
      border-radius: 10px;
      color: white;
      &:hover {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: $mobileBreakPoint) {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 50px;

    .Profile {
      .Text {
        color: $lightgrey;
      }

      .Avatar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        padding-bottom: 20px;

        .Photo {
          $size: 80px;
          width: $size;
          height: $size;
          border-radius: $size/2;
          border: 1px solid $lightgrey;
        }
      }

      .GroupProfile1 {
        width: 100%;
        .Fullname,
        .Email,
        .PhoneNumber,
        .Country {
          input,
          select {
            width: 90%;
            height: 30px;
            font-size: 12px;
          }
        }
      }
      .GroupProfile2 {
        width: 100%;
        input,
        select {
          width: 90%;
          height: 30px;
          font-size: 12px;
        }
        .NewPassword {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .ButtonContainer {
      padding-top: 20px;

      .CancelButton,
      .UpdateButton {
        padding: 5px 20px;
        font-size: 12px;
      }

      .CancelButton {
        margin-right: 10px;
      }
    }
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 130px;

    .Profile {
      .Avatar {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .Photo {
          $size: 155px;
          width: $size;
          height: $size;
          border-radius: $size/2;
          border: 1px solid $lightgrey;
        }
      }
      .GroupProfile {
        padding-top: 40px;
        .GroupProfile1 {
          margin-right: 40px;
        }

        .NewPassword {
          :first-child {
            padding-right: 45px;
          }
        }
      }

      input,
      select {
        height: 50px;
        width: 268px;
        font-size: 14px;
      }
      select {
        width: 288px;
      }
    }

    .ButtonContainer {
      padding-top: 70px;

      .CancelButton,
      .UpdateButton {
        padding: 10px 45px;
        font-size: 16px;
      }

      .CancelButton {
        margin-right: 20px;
      }
    }
  }
}
