// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

.result_container {
  height: 370px;
  width: 592px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 48px;
  margin-left: 138px;
  margin-bottom: 86px;
}

.result_container_header {
  width: auto;
  height: 35px;
  padding-bottom: 14.5px;
  border-bottom: solid 0.25px #707070;
}

.result_container_body {
  width: 100%;
  height: 216px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body_content_card {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.body_footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.result_container_footer {
  width: 293px;
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
}

.yellow_btn {
  width: 147px;
  height: 43px;
  border-radius: 10px;
  background-color: #B97A2A;
  color: white;
  font-weight: bold;
  border: none;
}

.grey_btn {
  width: 127px;
  height: 43px;
  border-radius: 10px;
  background-color: #D6D6D6;
  color: white;
  font-weight: bold;
  border: none;
}

.yellow_result_btn {
  width: 219px;
  height: 43px;
  border-radius: 10px;
  background-color: #B97A2A;
  color: white;
  font-weight: bold;
  border: none;
}

@media only screen and (max-width: $smallMobileBreakPoint) {

  .result_container {
    height: 400px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 48px;
    margin-left: 0px;
    margin-bottom: 86px;
  }

  .result_container_body {
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
  }

  .result_container_body p {
    width: auto;
  }

  .body_content_card {
    width: 100vw;
    height: 140px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .body_footer {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
  }

  .body_footer p {
    width: 240px;
    word-break: break-all;
  }
  .result_container_footer {
    width: 293px;
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    margin: auto;
    margin-top: 28px;
  }

  .result_container_header {
    font-size: 16px;
    padding-left: 10px;
  }

  .yellow_result_btn {
    width: 219px;
    height: 43px;
    border-radius: 10px;
    background-color: #B97A2A;
    color: white;
    font-weight: bold;
    border: none;
    margin: auto;
  }

  .yellow_result_btn + a {
    margin: auto;
  }
}