@import '../../../shared.scss';

.title-content-course-card-wrapper {
  @extend %fontBold;
}

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .course-card-wrapper {
    width: 255px;
    height: auto;
    margin: auto;
    margin-bottom: 16px;
    .content-course-card-wrapper {
      padding-bottom: 10px;
      margin-bottom: 12px;
      border-radius: 10px;
      box-shadow: 1px 2px rgba($color: #000000, $alpha: 0.16);
      position: relative;
      background-color: #FFFFFF;
      .cover-content-course-card-wrapper {
        width: 100%;
        height: 190px;
        margin-bottom: 18px;
        z-index: -1;
      }
      .price-content-course-card-wrapper {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 30px;
        border-radius: 10px;
        font-size: 14px;
        font-family: 'Muli-ExtraBold';
        color: #FFFFFF;
        background: #DDDDDD;
        top: -31px;
        left: 79px;
        background-color: $gold;
        p {
          margin-bottom: 0px;
        }
      }
      .title-content-course-card-wrapper {
        width: 100%;
        font-weight: bold;
        font-size: 12px;
        text-align: center !important;
        color: $darkgrey;
        border-bottom: 1px solid #70707070;
        padding-bottom: 4vw;
        margin-bottom: 10px;
      }
      .footer-content-course-card-wrapper {
        display: flex;
        width: 100%;
        height: 16px;
        flex-grow: 50% 50%;
        justify-content: space-around;
        .item-footer-content-course-card-wrapper {
          display: flex;
          align-items: center;
          .icon {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            color: $gold;
            margin-right: 2px;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            color: #707070;
            font-size: 10px;
            font-family: 'Roboto';
            margin-bottom: 0px;
          }
        }
        .line-footer-content-course-card-wrapper {
          height: 100%;
          width: 1px;
          background-color: #707070;
        }
      }
    }
    .action-course-card-wrapper {
      width: 100%;
      text-align: center;
      .btn-action-course-card-wrapper {
        width: 100px;
        height: 30px;
        border-radius: 10px;
        background-color: $brown;
        font-size: 10px;
        font-weight: 600;
        font-family: 'Muli';
        color: #FFFFFF;
        border: none;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $mobileBreakPoint) {
  .course-card-wrapper {
    width: 255px;
    height: auto;
    margin: auto;
    margin-bottom: 16px;
    .content-course-card-wrapper {
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-radius: 10px;
      box-shadow: 1px 2px rgba($color: #000000, $alpha: 0.16);
      position: relative;
      background-color: #FFFFFF;
      .cover-content-course-card-wrapper {
        width: 100%;
        height: 190px;
        margin-bottom: 39px;
      }
      .price-content-course-card-wrapper {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 116px;
        height: 41px;
        border-radius: 10px;
        font-size: 20px;
        font-family: 'Muli-ExtraBold';
        color: #FFFFFF;
        background: #DDDDDD;
        top: -58px;
        left: 70px;
        background-color: $gold;
        p {
          margin-bottom: 0px;
        }
      }
      .title-content-course-card-wrapper {
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        color: $darkgrey;
        border-bottom: 1px solid #70707070;
        padding-bottom: 10px;
        margin-bottom: 13px;
      }
      .footer-content-course-card-wrapper {
        display: flex;
        width: 100%;
        height: 30px;
        flex-grow: 50% 50%;
        justify-content: space-around;
        .item-footer-content-course-card-wrapper {
          display: flex;
          align-items: center;
          .icon {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            color: $gold;
            margin-right: 6px;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            color: #707070;
            font-size: 13px;
            font-family: 'Roboto';
            margin-bottom: 0px;
          }
        }
        .line-footer-content-course-card-wrapper {
          height: 100%;
          width: 1px;
          background-color: #707070;
        }
      }
    }
    .action-course-card-wrapper {
      width: 100%;
      text-align: center;
      .btn-action-course-card-wrapper {
        width: 231px;
        height: 41px;
        border-radius: 10px;
        background-color: $brown;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Muli';
        color: #FFFFFF;
        border: none;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $mobileBreakPoint + 1) {
  .course-card-wrapper {
    width: 255px;
    height: auto;
    margin: auto;
    .content-course-card-wrapper {
      padding-bottom: 12px;
      margin-bottom: 12px;
      border-radius: 10px;
      box-shadow: 1px 2px rgba($color: #000000, $alpha: 0.16);
      position: relative;
      background-color: #FFFFFF;
      .cover-content-course-card-wrapper {
        width: 100%;
        height: 190px;
        margin-bottom: 39px;
      }
      .price-content-course-card-wrapper {
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 116px;
        height: 41px;
        border-radius: 10px;
        font-size: 20px;
        font-family: 'Muli-ExtraBold';
        color: #FFFFFF;
        background: #DDDDDD;
        top: -58px;
        left: 70px;
        background-color: $gold;
        p {
          margin-bottom: 0px;
        }
      }
      .title-content-course-card-wrapper {
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        color: $darkgrey;
        border-bottom: 1px solid #70707070;
        padding-bottom: 10px;
        margin-bottom: 13px;
      }
      .footer-content-course-card-wrapper {
        display: flex;
        width: 100%;
        height: 30px;
        flex-grow: 50% 50%;
        justify-content: space-around;
        .item-footer-content-course-card-wrapper {
          display: flex;
          align-items: center;
          .icon {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            color: $gold;
            margin-right: 6px;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          p {
            color: #707070;
            font-size: 13px;
            font-family: 'Roboto';
            margin-bottom: 0px;
          }
        }
        .line-footer-content-course-card-wrapper {
          height: 100%;
          width: 1px;
          background-color: #707070;
        }
      }
    }
    .action-course-card-wrapper {
      width: 100%;
      text-align: center;
      .btn-action-course-card-wrapper {
        width: 231px;
        height: 41px;
        border-radius: 10px;
        background-color: $brown;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Muli';
        color: #FFFFFF;
        border: none;
      }
    }
  }
}
