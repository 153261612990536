@import '../../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .audio-book-item-wrapper {
    width: 100%;
    margin-bottom: 9.688vw;
    .cover-document-card-wrapper {
      width: 100%;
      height: 65.625vw;
      position: relative;
      margin-bottom: 3.125vw;
      .image-cover-document-card-wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-document-card-wrapper {
      width: 100%;
      p {
        font-size: 13px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint - 1) {
  .audio-book-item-wrapper {
    width: 100%;
    margin: auto;
    margin-bottom: 4.375vw;
    .cover-document-card-wrapper {
      width: 100%;
      height: 32.552vw;
      position: relative;
      margin-bottom: 1.042vw;
      .image-cover-document-card-wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-document-card-wrapper {
      p {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}

//1024//
@media only screen and (min-width: $inBetweenBreakpoint) {
  .audio-book-item-wrapper {
    width: 100%;
    margin: auto;
    margin-bottom: 63px;
    .cover-document-card-wrapper {
      width: 100%;
      height: 254px;
      position: relative;
      margin-bottom: 1.042vw;
      .image-cover-document-card-wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-document-card-wrapper {
      p {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  .audio-book-item-wrapper {
    width: 100%;
    margin-bottom: 63px;
    .cover-document-card-wrapper {
      width: 100%;
      height: 260px;
      position: relative;
      margin-bottom: 15px;
      .image-cover-document-card-wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
      } 
    }
    .content-document-card-wrapper {
      p {
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}