@import "../../shared.scss";
@import "../../assets/font/font.scss";

%LoginHeader {
  @extend %fontExtraBold;
  color: $brown;
  @extend %fontHeader;
  text-align: center;
  padding-bottom: 5px;
}

%styleLoginSelected {
  border-bottom: 3px solid $brown;
}

%styleButton {
  background-color: $brown;
  color: white;
  @extend %fontBold;
  border-radius: 10px;
  cursor: pointer;

  @media only screen and (max-width: $mobileBreakPoint) {
    padding: 5px 25px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding: 12px 100px;
    text-align: center;
  }
}

.SigninSignupContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: $mobileBreakPoint) {
    margin-left: 25px;
    margin-right: 25px;
    padding-top: 10px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 130px;
  }

  .Head {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    .Title {
      width: 45%;

      @media only screen and (max-width: $mobileBreakPoint) {
        margin-bottom: 10px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-top: 25px;
        margin-bottom: 25px;
        padding-left: 50px;
        padding-right: 50px;
      }

      span {
        @extend %LoginHeader;
      }

      &:hover {
        @extend %styleLoginSelected;
        cursor: pointer;
      }
    }
    .Active {
      @extend %styleLoginSelected;
    }
  }

  .Signin,
  .Signup {
    margin: 0 auto;
    @media only screen and (max-width: $mobileBreakPoint) {
      width: 80%;
      .courses-select-wrap {
        width: 53.76vw;
        margin-left: -8.77vw;
        margin-top: 2.04vw;
        .content-courses-select-wrap {
          margin-top: 1.04vw;
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .main-content-course-select-wrap {
            display: flex;
            width: 100%;
            .ant-checkbox-wrapper {
              margin-right: 0.83vw;
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: $gold;
                  border-color: $gold;
                }
              }
            }
            .ant-checkbox:hover {
              .ant-checkbox-inner {
                border-color: $gold;
              }
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      width: 45%;
      .courses-select-wrap {
        width: 53.76vw;
        margin-left: -10.77vw;
        margin-top: 2.04vw;
        .content-courses-select-wrap {
          margin-top: 1.04vw;
          display: flex;
          width: 100%;
          justify-content: space-between;
          .main-content-course-select-wrap {
            display: flex;
            .ant-checkbox-wrapper {
              margin-right: 0.83vw;
              .ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: $gold;
                  border-color: $gold;
                }
              }
            }
            .ant-checkbox:hover {
              .ant-checkbox-inner {
                border-color: $gold;
              }
            }
          }
        }
      }
    }
  }

  .BodySpacingTop {
    margin-top: 20px;
  }

  .Body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid $lightgrey;
    box-sizing: border-box;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding: 10px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      padding: 30px 50px;
    }

    .Email,
    .Password,
    .Username,
    .Fullname,
    .Sponsor,
    .PhoneNumber,
    .Country,
    .RePassword {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media only screen and (max-width: $mobileBreakPoint) {
        margin-bottom: 10px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        margin-bottom: 25px;
      }

      span {
        @extend %fontExtraBold;
        color: $grey;
      }

      input,
      select {
        border: none;
        margin-top: 7px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
        @extend %fontSemiBold;
        color: $darkgrey;
        padding-left: 10px;
        padding-right: 10px;

        @media only screen and (max-width: $mobileBreakPoint) {
          height: 25px;
          font-size: 12px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          font-size: 14px;
          height: 50px;
        }
      }

      .Note {
        color: red;
        font-size: 10px;
        font-style: italic;
        padding-top: 5px;
      }
    }

    .danger{
      input {
        border-color: red !important;
      }

      p {
        color: red !important;
        font-style: italic;
        font-size: 0.8em;
      }
    }

    .ForgotPassword {
      font-style: italic;
      cursor: pointer;
    }
  }
  .Button {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    @media only screen and (max-width: $mobileBreakPoint) {
      margin-top: 20px;
      margin-bottom: 50px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      margin-top: 70px;
    }

    .SigninButton,
    .SignupButton {
      text-align: center;
      margin: 0 auto;
      width: 30%;
      box-sizing: border-box;
      @extend %styleButton;
    }
  }
}

.ForgotPasswordPopup {

  .CancelButton {
    img {
      position: absolute;
      cursor: pointer;
      @media only screen and (max-width: $mobileBreakPoint) {
        $size: 20px;
        top: -$size / 2;
        right: -$size / 2;
        width: $size;
        height: $size;
      }
      @media only screen and (min-width: $mobileBreakPoint + 1) {
        $size: 32px;
        top: -$size / 2;
        right: -$size / 2;
        width: $size;
        height: $size;
      }
    }
  }

  .Title {
    @extend %fontBold;
    @extend %fontHeader;
    color: grey;
    text-align: center;
    border-bottom: 1px solid $darkgrey;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      padding-bottom: 25px;
      margin-bottom: 40px;
    }
  }

  input {
    box-sizing: border-box;
    border: none;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    color: $darkgrey;
    font-size: 14px;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding: 5px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      width: 372px;
      height: 52px;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 45px;
    }
  }
  ::placeholder {
    font-style: italic;
    font-size: 10px;
    @media only screen and (max-width: $mobileBreakPoint) {
      font-size: 8px;
    }
  }

  .SendButton {
    margin-bottom: 5px;
    margin-top: 5px;
    span {
      color: white;
      @extend %fontBold;
      background-color: $brown;
      box-sizing: border-box;
      border-radius: 10px;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding: 5px 25px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding: 12px 90px;
      }
    }
  }
}
