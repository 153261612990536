// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

.custom-card-modal {

  width: 618px;

  @media only screen and (max-width: $smallMobileBreakPoint) {
    width: 339px;
  }
  .ant-card-body {
    padding: 18px 60px;

    @media only screen and (max-width: $smallMobileBreakPoint) {
      padding: 18px 19px;
    }
  }

  .ant-card-meta-description {
    
    #extra-container-header {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;
      border-top: solid 0.5px #000000;
      border-bottom: solid 0.5px #000000;

      #header-score-text {
        color: #B97A2A;
      }
    }

    #extra-container-body {
      margin-top: 28px;

      #form-wrapper {
        margin-top: 14px;

        .ant-row {
          .ant-col {
            text-align: center;
            #btn-cancel {
              border: none;
              width: 129px;
              height: 37px;
              font-weight: bold;
              font-size: 15px;
              color: #929292;
              border-radius: 10px;
              @media only screen and (max-width: $smallMobileBreakPoint) {
                width: 108px;
              }
            }
            .ant-form-item {
              .ant-form-item-control {
                .ant-form-item-control-input {
                  .ant-form-item-control-input-content {
                    input {
                      border: solid 1px #707070 !important;
                      border-radius: 10px;
                      height: 36px;
                    }

                    button {
                      width: 193px;
                      height: 37px;
                      border-radius: 10px;
                      background-color: #B97A2A;
                      font-weight: bold;
                      color: #FFFFFF;
                      font-size: 15px;

                      @media only screen and (max-width: $smallMobileBreakPoint) {
                        width: 108px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}