// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

#privilege-container {
  width: 820px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-left: 61px;

  @media only screen and (max-width: $smallMobileBreakPoint) {
    width: 339px;
    margin-left: 0px;
  }

  @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
    width: 100%;
    margin-left: 0px;
  }

  #privilege-container-header {
    position: absolute;
    background-color: #d6d6d6;
    width: 268px;
    height: 57px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 19px;
    justify-content: space-between;
    p {
      color: #b97a2a;
      font-size: 16px;
      font-weight: bold;
    }
  }

  #privilege-container-body {
    border: solid 0.5px #d59e29;
    margin-top: 28px;
    margin-bottom: 108px;
    width: 100%;
    padding: 62px 37px;

    @media only screen and (max-width: $smallMobileBreakPoint) {
      padding: 62px 10px;
    }

    @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
      padding: 62px 10px;
    }

    #body-button-container-member {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 103px;
      justify-content: space-between;
      margin-bottom: 20px;

      @media only screen and (max-width: $smallMobileBreakPoint) {
        padding: 0px 0px;
      }
      button {
        width: 224px;
        @media only screen and (max-width: $smallMobileBreakPoint) {
          width: 147px;
        }
        height: 48px;
        border-radius: 20px;
        background-position: center;
        background-image: url('../../../assets/images/member_backgroud.png');
        border: none;
        font-weight: 800;
        color: black;
      }
      button:focus {
        outline: none;
        color: #b97a2a;
      }
    }

    #body-button-container-gold {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 103px;
      justify-content: space-between;
      margin-bottom: 20px;

      @media only screen and (max-width: $smallMobileBreakPoint) {
        padding: 0px 0px;
      }
      button {
        width: 224px;
        @media only screen and (max-width: $smallMobileBreakPoint) {
          width: 147px;
        }
        height: 48px;
        border-radius: 20px;
        background-position: center;
        background-image: url('../../../assets/images/gold_backgroud.png');
        border: none;
        font-weight: 800;
        color: #929292;
      }
      button:focus {
        outline: none;
        color: #b97a2a;
      }
    }

    #body-button-container-platinum {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 103px;
      justify-content: space-between;
      margin-bottom: 20px;

      @media only screen and (max-width: $smallMobileBreakPoint) {
        padding: 0px 0px;
      }
      button {
        width: 224px;
        @media only screen and (max-width: $smallMobileBreakPoint) {
          width: 147px;
        }
        height: 48px;
        border-radius: 20px;
        background-position: center;
        background-image: url('../../../assets/images/buttonbackgroud.png');
        border: none;
        font-weight: 800;
        color: black;
      }
      button:focus {
        outline: none;
        color: #b97a2a;
      }
    }
    .swap-money-wrapper {
      @media only screen and (max-width: $smallMobileBreakPoint) {
        .roles {
          flex-direction: column;
        }
        .form-swap {
          flex-direction: column;
          .field-point {
            margin-bottom: 20px;
          }
        }
      }
      .roles {
        display: flex;
        justify-content: space-between;
        .current-point {
          .label-point {
            color: #707070;
            font-size: 14px;
            font-weight: 600;
          }
          .points {
            padding: 18px 46px;
            color: #707070;
            font-size: 20px;
            font-weight: bold;
            margin-right: 12px;
            border-radius: 8px;
            border: 1px solid #b97a2a;
          }
        }
        .role-swap {
          .label-role {
            color: #707070;
            font-size: 14px;
            font-weight: 600;
          }
          .role-point {
            padding: 18px 40px;
            background: #d59e29;
            color: white;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
      .form-swap {
        display: flex;
        justify-content: space-between;
        max-width: 400px;
        margin-top: 40px;
        .field-point {
          max-width: 200px;
          width: 100%;
          .label-field-point {
            color: #707070;
            font-size: 14px;
            font-weight: 600;
          }
          .input-point {
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            border-bottom: 1px solid #707070 !important;
            box-shadow: none !important;
            width: 100%;
          }
        }
        .field-money {
          .label-money {
            color: #707070;
            font-size: 14px;
            font-weight: 600;
          }
          .money {
            width: 100%;
            border-bottom: 1px solid #707070;
          }
        }
      }
      .group-btn {
        display: flex;
        justify-content: space-between;
        max-width: 450px;
        margin-top: 40px;
        .btn-swap {
          color: #f70c1a;
          background: #d59e29;
          font-size: 15px;
          font-weight: bold;
          border-radius: 16px;
          width: 100%;
          height: 40px;
          margin-right: 60px;
        }
        .btn-cancel {
          color: #000000;
          background: #d6d6d6;
          font-size: 15px;
          font-weight: bold;
          border-radius: 16px;
          border: 1px solid #b97a2a;
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}

.modal-swap__point__success {
  .ant-modal-content {
    .ant-modal-header {
      .ant-modal-title {
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
    }
    .ant-modal-body {
      padding: 24px;
    }
  }
  .footer-modal {
    margin-top: 40px;
    text-align: center;
    .btn-cancel {
      background: #d6d6d6;
      color: #929292;
      font-size: 15px;
      font-weight: 500;
      margin-right: 30px;
      padding: 10px 20px;
      border-radius: 8px;
    }
    .btn-vist-wallet {
      color: white;
      font-size: 15px;
      font-weight: 500;
      background: #d59e29;
      padding: 10px 20px;
      border-radius: 8px;
    }
  }
  .label {
    font-size: 17px;
  }
  .success {
    color: #b97a2a;
    font-size: 20px;
    font-weight: bold;
  }
}

.custom-modal {
  .ant-modal-content {
    width: 618px;
    @media only screen and (max-width: $smallMobileBreakPoint) {
      width: 339px;
    }
    .ant-modal-body {
      padding: 0px;
    }
  }
}
