@import '../../shared.scss';
@import '../../assets/font/font.scss';

.MyWallet {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 130px;
  }

  .Title {
    color: $darkblue;
    padding-bottom: 5px;
    border-bottom: 1px solid $lightgrey;
    @extend %fontHeader;

    @media only screen and (max-width: $mobileBreakPoint) {
      margin-bottom: 20px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      margin-bottom: 40px;
    }
  }

  .Card {
    background-image: url(../../assets/images/background_card.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;

    @media only screen and (max-width: $mobileBreakPoint) {
      max-width: 300px;
      height: 170px;
      background-size: cover;

      padding-top: 10px;
      padding-left: 15px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      width: 426px;
      height: 240px;
      box-sizing: border-box;
      padding-top: 5px;
      padding-left: 25px;
    }

    .User {
      @media only screen and (max-width: $mobileBreakPoint) {
        img {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        img {
          width: 55px;
          height: 55px;
          margin-right: 10px;
        }
      }

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      @extend %fontBold;
    }

    .Balance {
      color: white;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding-top: 70px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-top: 85px;
      }

      .Text {
        @media only screen and (max-width: $mobileBreakPoint) {
          font-size: 14px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          font-size: 20px;
        }
      }

      .Number {
        @extend %fontBold;

        @media only screen and (max-width: $mobileBreakPoint) {
          font-size: 20px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          font-size: 41px;
        }
      }
    }
  }

  .ButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    &:hover {
      cursor: pointer;
    }
    @media only screen and (max-width: $mobileBreakPoint) {
      padding-top: 15px;
      padding-bottom: 30px;
      justify-content: space-evenly;

      .WithdrawButton,
      .TopupButton {
        font-size: 12px;
        padding: 5px 25px;
        border-radius: 5px;
      }
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      padding-top: 40px;
      padding-bottom: 40px;
      justify-content: space-between;
      width: 426px;

      .WithdrawButton,
      .TopupButton {
        font-size: 16px;
        padding: 10px 50px;
        border-radius: 10px;
      }
    }

    .WithdrawButton,
    .TopupButton {
      background-color: $brown;
      color: white;
      @extend %fontSemiBold;
      cursor: pointer;
    }
  }

  .Transactions {
    .Text {
      @extend %fontSemiBold;

      @media only screen and (max-width: $mobileBreakPoint) {
        font-size: 12px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        font-size: 16px;
      }
    }

    .Actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      &:hover {
        cursor: pointer;
      }

      @media only screen and (max-width: $mobileBreakPoint) {
        padding-top: 10px;
        padding-bottom: 10px;

        .Withdraw {
          margin-right: 40px;
        }
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-top: 25px;
        padding-bottom: 25px;

        .Withdraw {
          margin-right: 200px;
        }
      }

      .Withdraw,
      .Topup {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        @media only screen and (max-width: $mobileBreakPoint) {
          padding-bottom: 2px;
        }
        &.ActiveTab {
          border-bottom: 3px solid $brown;
        }
      }
    }

    table {
      width: 100%;
      thead {
        tr {
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
          color: $grey;
        }
        @media only screen and (max-width: $mobileBreakPoint) {
          tr,
          th {
            padding: 10px 0;
            text-align: center;
          }
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          tr,
          th {
            padding: 25px 0;
            text-align: center;
          }
        }
      }

      tbody {
        tr {
          color: black;
        }
        @media only screen and (max-width: $mobileBreakPoint) {
          tr td {
            border-bottom: 1px solid $lightgrey;
            text-align: center;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          tr td {
            border-bottom: 1px solid $lightgrey;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

.pagination {
  display: inline-block;

  a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;

    &.active{
      background-color: #b97a2a;
    }
  }
}
