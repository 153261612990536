.question_list_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-around;
}

.list_container {
  margin: auto;
  margin-top: 96px;
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list_item {
  height: 180px;
}

.link_center {
  margin: auto;
}

.yellow_light_btn {
  width: 201px;
  height: 44px;
  background-color: #FAC857;
  border-radius: 10px;
  border: none;
  margin: auto;
  margin-top: 52px;
  margin-bottom: 62px;
  color: white;
  font-weight: bold;
}