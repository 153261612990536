@import '../../shared.scss';


p {
  font-family: 'Poppins', sans-serif;
}

.card_right_container {
  position: relative;
  width: 254px;
  height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card_right_header {
  color: #3794FC;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
}

.card_badge {
  position: absolute;
  top: -15px;
  right: -14px;
}

@media only screen and (max-width: $smallMobileBreakPoint) {
  .card_right_container {
    position: relative;
    width: 184px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  .card_right_header {
    color: #3794FC;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    margin-bottom: 0px;
  }
  
  .card_badge {
    position: absolute;
    top: -15px;
    right: -14px;
  }
}