// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

#membermedal-container {
  width: 110px;
  height: 110px;
  background: none;
  border: none;
  @media only screen and (max-width: $smallMobileBreakPoint) {
    width: 85px;
    height: 85px;
    
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
    width: 85px;
    height: 85px;
    
    img {
      width: 100%;
      height: 100%;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  #text-rank {
    font-weight: bold;
    margin-top: 8px;
    color: #929292;
    font-size: 20px;
    @media only screen and (max-width: $smallMobileBreakPoint) {
      font-size: 15px;
    }
    @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
      font-size: 15px;
    }
  }

  #text-note {
    font-size: 10px;
    color: #929292;
    @media only screen and (max-width: $smallMobileBreakPoint) {
      font-size: 7px;
    }
    @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
      font-size: 7px;
    }
  }

  #text-rank-current {
    color: #B97A2A;
    margin-top: 8px;
    font-size: 20px;
    @media only screen and (max-width: $smallMobileBreakPoint) {
      font-size: 15px;
    }
    @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
      font-size: 15px;
    }
  }

  #text-note-current {
    color: #B97A2A;
    font-size: 10px;
    @media only screen and (max-width: $smallMobileBreakPoint) {
      font-size: 7px;
    }
    @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
      font-size: 7px;
    }
  }

  #pointer {
    width: 100px;
    border: solid 2px #B97A2A;
    border-radius: 2px;
    margin-top: 8px;
  }
}

#membermedal-container:hover {
  #text-note {
    color: #B97A2A;
  }

  #text-rank {
    color: #B97A2A;
  }
}

#membermedal-container:focus {

  outline: none;
  #text-note {
    color: #B97A2A;
  }

  #text-rank {
    color: #B97A2A;
  }
}