@import '../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  // LIST SPEAKER WRAPPER
  #list-speaker-wrapper {
    width: 340px;
    margin: auto;
    margin-bottom: 46px;
    margin-top: 27px;
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  #list-speaker-wrapper {
    // width: 1089px;
    // max-width: 1089px;
    margin: auto;
    margin-bottom: 5.556vw;
    margin-top: 4.028vw;
    padding: 0px 25px;
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  // LIST SPEAKER WRAPPER
  #list-speaker-wrapper {
    width: 1089px;
    max-width: 1089px;
    margin: auto;
    margin-bottom: 80px;
    margin-top: 58px;
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }
}