@import '../../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .course-information-wrapper {
    width: 70%;
    margin: auto;
    padding: 10px 12px 0px 12px;
    border: solid 1px $brown;
    border-top: solid 5px $brown;
    box-shadow: 1px 2px rgba($color: $gold, $alpha: 0.16);
    .title-course-information-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      #text-title-course-information-wrapper {
        font-size: 14px;
        font-family: 'Muli';
        color: $darkgrey;
        font-weight: bold;
      }
      #course-name-title-course-information-wrapper {
        font-size: 16px;
        font-family: 'Muli';
        color: #707070;
        font-weight: bold;
      }
    }
    #login-price-control-course-information-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      height: 75px;
      #price-login-price-control-course-information-wrapper {
        color: #707070;
        font-size: 8px;
        svg {
          color: $gold;
          border: solid;
          border-radius: 50%;
          width: 26px;
          height: auto;
          margin-right: 8px;
        }
      }
      #control-login-price-control-course-information-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        .item-control-login-price-control-course-information-wrapper {
          box-sizing: border-box;
          margin-bottom: 2px;
          height: 30.02px;
          text-align: center;
          p {
            color: #707070;
            font-size: 9px;
            font-family: 'Muli';
            margin-top: 5px;
          }
          .price-item-control-login-price-control-course-information-wrapper {
            width: 65px;
            height: 25px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: auto;
            p {
              font-size: 12px;
              color: #FFFFFF;
              font-family: 'Muli';
              font-weight: bold;
              margin-bottom: 0px;
              margin-top: 0px;
            }
          }
        }
      }
    }
    #content-course-information-wrapper {
      padding-top: 5px;
      border-top: solid 1px #707070;
      .item-content-course-information-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin-bottom: 5px;
        .icon {
          color: $gold;
        }
        .text {
          flex-grow: 1;
          padding-left: 7px;
          font-family: Poppins;
          font-size: 10px;
        }
        .number {
          font-size: 10px;
        }
      }
    }
    #checkbox-control-course-information-wrapper {
      display: flex;
      padding-top: 6px;
      padding-bottom: 7px;
      border-bottom: solid 1px #707070;
      border-top: solid 1px #707070;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 10px;
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $gold;
          border-color: $gold;
        }
      }
      #price-checkbox-control-course-information-wrapper {
        width: 65px;
        height: 18px;
        border-radius: 10px;
        background-color: $gold;
        text-align: center;
        p {
          font-size: 12px;
          color: #FFFFFF;
          margin-bottom: 0px;
        }
      } 
    }
    #control-course-information-wrapper {
      text-align: center;
      padding-top: 5px;
      margin-bottom: 9px;
      button {
        width: 120px;
        height: 30px;
        border-radius: 10px;
        background-color: $brown;
        color: #FFFFFF;
        font-size: 10px;
        font-weight: bold;
        border: none;
      }
    }
  }
  .InsurranceBox {
    // max-width: 362px;
    // border: solid 1px #707070;
    width: 70%;
    box-shadow: 1px 1px rgba($color: #707070, $alpha: 0.6);
    margin: auto;
    margin-top: 20px;
    padding: 0;
    background: url(../../../../../assets/images/background-insurrancebox.png);
    .TitleContainer {
      display: flex;
      border-bottom: 1px solid #d59e29;
      .PrivilegesTitle {
        width: 140px;
        height: 110px;
        background: #d59e29;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        > p {
          padding: 33px 20px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .BoxInsurranceTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        > p {
          text-transform: uppercase;
          color: #b97a2a;
        }
      }
    }
    .TitleFeeContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
    }
    .FeeBox {
      display: flex;
      justify-content: center;
      .Box {
        width: 138px;
        height: 40px;
        background-color: #d59e29;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          padding-bottom: 16px;
          padding-left: 4px;
        }
      }
    }
    hr {
      border-bottom: 0.5px solid #929292;
      margin: 0px 18px;
    }
    .ButtonBuyContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .BuyButton {
        background: #b97a2a;
        text-transform: uppercase;
        padding: 9px 40px;
        border-radius: 999px;
        color: #fff;
      }
    }
    .QuestionContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 30px 0;
      > p > i {
        color: #707070;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $mobileBreakPoint) {
  .course-information-wrapper {
    width: 70%;
    margin: auto;
    padding: 10px 12px 0px 12px;
    border: solid 1px $brown;
    border-top: solid 5px $brown;
    box-shadow: 1px 2px rgba($color: $gold, $alpha: 0.16);
    .title-course-information-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      #text-title-course-information-wrapper {
        font-size: 14px;
        font-family: 'Muli';
        color: $darkgrey;
        font-weight: bold;
      }
      #course-name-title-course-information-wrapper {
        font-size: 16px;
        font-family: 'Muli';
        color: #707070;
        font-weight: bold;
      }
    }
    #login-price-control-course-information-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      height: 75px;
      #price-login-price-control-course-information-wrapper {
        color: #707070;
        font-size: 8px;
        svg {
          color: $gold;
          border: solid;
          border-radius: 50%;
          width: 26px;
          height: auto;
          margin-right: 8px;
        }
      }
      #control-login-price-control-course-information-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        .item-control-login-price-control-course-information-wrapper {
          box-sizing: border-box;
          margin-bottom: 2px;
          height: 30.02px;
          text-align: center;
          p {
            color: #707070;
            font-size: 9px;
            font-family: 'Muli';
            margin-top: 5px;
          }
          .price-item-control-login-price-control-course-information-wrapper {
            width: 65px;
            height: 25px;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            // background-color: $gold;
            text-align: center;
            margin: auto;
            p {
              font-size: 12px;
              color: #FFFFFF;
              font-family: 'Muli';
              font-weight: bold;
              margin-bottom: 0px;
              margin-top: 0px;
            }
          }
        }
      }
    }
    #content-course-information-wrapper {
      padding-top: 5px;
      border-top: solid 1px #707070;
      .item-content-course-information-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        margin-bottom: 5px;
        .icon {
          color: $gold;
        }
        .text {
          flex-grow: 1;
          padding-left: 7px;
          font-family: Poppins;
          font-size: 10px;
        }
        .number {
          font-size: 10px;
        }
      }
    }
    #checkbox-control-course-information-wrapper {
      display: flex;
      padding-top: 6px;
      padding-bottom: 7px;
      border-bottom: solid 1px #707070;
      border-top: solid 1px #707070;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 10px;
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $gold;
          border-color: $gold;
        }
      }
      #price-checkbox-control-course-information-wrapper {
        width: 65px;
        height: 18px;
        border-radius: 10px;
        background-color: $gold;
        text-align: center;
        p {
          font-size: 12px;
          color: #FFFFFF;
          margin-bottom: 0px;
        }
      } 
    }
    #control-course-information-wrapper {
      text-align: center;
      padding-top: 5px;
      margin-bottom: 9px;
      button {
        width: 120px;
        height: 30px;
        border-radius: 10px;
        background-color: $brown;
        color: #FFFFFF;
        font-size: 10px;
        font-weight: bold;
        border: none;
      }
    }
  }
  .InsurranceBox {
    // max-width: 362px;
    // border: solid 1px #707070;
    width: 70%;
    box-shadow: 1px 1px rgba($color: #707070, $alpha: 0.6);
    margin: auto;
    margin-top: 20px;
    padding: 0;
    background: url(../../../../../assets/images/background-insurrancebox.png);
    .TitleContainer {
      display: flex;
      border-bottom: 1px solid #d59e29;
      .PrivilegesTitle {
        width: 140px;
        height: 110px;
        background: #d59e29;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        > p {
          padding: 33px 20px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .BoxInsurranceTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        > p {
          text-transform: uppercase;
          color: #b97a2a;
        }
      }
    }
    .TitleFeeContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
    }
    .FeeBox {
      display: flex;
      justify-content: center;
      .Box {
        width: 138px;
        height: 40px;
        background-color: #d59e29;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          padding-bottom: 16px;
          padding-left: 4px;
        }
      }
    }
    hr {
      border-bottom: 0.5px solid #929292;
      margin: 0px 18px;
    }
    .ButtonBuyContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .BuyButton {
        background: #b97a2a;
        text-transform: uppercase;
        padding: 9px 40px;
        border-radius: 999px;
        color: #fff;
      }
    }
    .QuestionContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 30px 0;
      > p > i {
        color: #707070;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $mobileBreakPoint + 1) {
  .course-information-wrapper {
    border: solid 1px #c4c4c4;
    max-width: 362px;
    padding: 15px 12px 0px 12px;
    border-top: solid 0.52vw $brown;
    box-shadow: 1px 2px rgba($color: $gold, $alpha: 0.16);
    .title-course-information-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      #text-title-course-information-wrapper {
        font-size: 14px;
        font-family: 'Muli';
        color: $darkgrey;
        font-weight: bold;
      }
      #course-name-title-course-information-wrapper {
        font-size: 20px;
        font-family: 'Muli';
        color: #707070;
        font-weight: bold;
      }
    }
    #login-price-control-course-information-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
      padding-bottom: 10px;
      // height: 81.82px;
      #price-login-price-control-course-information-wrapper {
        color: #707070;
        font-size: 14px;
        svg {
          color: $gold;
          border: solid;
          border-radius: 50%;
          width: 20px;
          height: auto;
          margin-right: 8px;
        }
      }
      #control-login-price-control-course-information-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 70%;
        .item-control-login-price-control-course-information-wrapper {
          box-sizing: border-box;
          margin-bottom: 7px;
          height: 40px;
          text-align: center;
          p {
            color: #707070;
            font-size: 10px;
            font-family: 'Muli';
            margin-top: 5px;
          }
          .price-item-control-login-price-control-course-information-wrapper {
            width: 80px;
            height: 30px;
            border-radius: 8px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            p {
              font-size: 18px;
              color: #FFFFFF;
              font-family: 'Muli';
              margin-bottom: 0px;
              margin-top: 0px;
            }
          }
        }
      }
    }
    #content-course-information-wrapper {
      padding-top: 17px;
      border-top: solid 1px #707070;
      .item-content-course-information-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
        margin-bottom: 20px;
        .icon {
          font-size: 10px;
          color: $gold;
        }
        .text {
          flex-grow: 1;
          padding-left: 0.29vw;
          font-family: Poppins;
          font-size: 12px;
        }
      }
    }
    #checkbox-control-course-information-wrapper {
      display: flex;
      padding-top: 23px;
      padding-bottom: 20px;
      border-bottom: solid 1px #707070;
      border-top: solid 1px #707070;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 12px;
      }
      .ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: $gold;
          border-color: $gold;
        }
      }
      #price-checkbox-control-course-information-wrapper {
        width: 80px;
        height: 30px;
        border-radius: 8px;
        background-color: $gold;
        text-align: center;
        p {
          font-size: 17px;
          color: #FFFFFF;
          margin-bottom: 0px;
        }
      } 
    }
    #control-course-information-wrapper {
      text-align: center;
      padding-top: 16px;
      margin-bottom: 30px;
      button {
        width: 220px;
        height: 46px;
        border-radius: 7px;
        background-color: $brown;
        color: #FFFFFF;
        font-size: 17px;
        font-weight: bold;
        border: none;
      }
    }
  }
  .InsurranceBox {
    max-width: 362px;
    // border: solid 1px #707070;
    box-shadow: 1px 1px rgba($color: #707070, $alpha: 0.6);
    margin-top: 20px;
    padding: 0;
    background: url(../../../../../assets/images/background-insurrancebox.png);
    .TitleContainer {
      display: flex;
      border-bottom: 1px solid #d59e29;
      .PrivilegesTitle {
        width: 140px;
        height: 110px;
        background: #d59e29;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        > p {
          padding: 33px 20px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .BoxInsurranceTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        > p {
          text-transform: uppercase;
          color: #b97a2a;
        }
      }
    }
    .TitleFeeContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
    }
    .FeeBox {
      display: flex;
      justify-content: center;
      .Box {
        width: 138px;
        height: 40px;
        background-color: #d59e29;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          padding-bottom: 16px;
          padding-left: 4px;
        }
      }
    }
    hr {
      border-bottom: 0.5px solid #929292;
      margin: 0px 18px;
    }
    .ButtonBuyContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .BuyButton {
        background: #b97a2a;
        text-transform: uppercase;
        padding: 9px 40px;
        border-radius: 999px;
        color: #fff;
      }
    }
    .QuestionContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 30px 0;
      > p > i {
        color: #707070;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  } 
}