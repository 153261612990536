@import '../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  // DOCUMENT LIST WRAPPER
  #document-list-wrapper {
    width: 340px;
    margin: auto;
    margin-bottom: 40px;
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  // DOCUMENT LIST WRAPPER
  #document-list-wrapper {
    padding: 25px 25px;
    margin: auto;
    margin-bottom: 6.319vw;
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  // DOCUMENT LIST WRAPPER
  #document-list-wrapper {
    width: 1089px;
    max-width: 1089px;
    margin: auto;
    margin-bottom: 91px;
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }
}
