@import '../../../../../shared.scss';

@media only screen and (max-width: $mobileBreakPoint) {
  #curriculum-infor-wrapper {
    .course-curriculum-infor-wrapper {
      border: none;
      .ant-collapse-header {
        background-color: $gold;
        font-weight: bold;
        color: #FFFFFF;
      }
      .ant-collapse-content-box {
        padding: 16px 0px !important;
      }
      .ant-collapse {
        border: none;
        margin-bottom: 2px;
      }
      .term-course-curriculum-infor-wrapper {
        .ant-collapse-header {
          background-color: #DDDDDD;
          color: $darkgrey;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            .lesson-term-course-curriculum-infor-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding:0px 10px 16px 10px;
              border-bottom: 1px solid #DDDDDD;
              margin-top: 15px;
              .infor-lesson-term-course-curriculum-infor-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                .title-infor-lesson-term-course-curriculum-infor-wrapper {
                  font-size: 14px;
                  color: #707070;
                  margin-left: 8px;
                  font-family: 'Muli-Regular';
                }
              }
              .duration-infor-lesson-term-course-curriculum-infor-wrapper {
                font-size: 14px;
                color: #707070;
                margin-left: 4px;
                font-family: 'Muli-Regular';
              }
              .infor-lesson-term-course-curriculum-infor-wrapper-watch {
                display: flex;
                flex-direction: row;
                align-items: center;
                .title-infor-lesson-term-course-curriculum-infor-wrapper {
                  font-size: 14px;
                  color: red;
                  margin-left: 8px;
                }
                .control-lesson-term-course-curriculum-infor-wrapper {
                  button {
                    span {
                      svg {
                        color: red;
                      }
                    }
                  }
                }
              }
              .duration-infor-lesson-term-course-curriculum-infor-wrapper-watch {
                color: red;
              }
              .control-lesson-term-course-curriculum-infor-wrapper {
                button {
                  padding: 0px;
                  border: none;
                  text-align: center;
                  font-size: 14px;
                  background: none;
                  display: flex;
                  align-items: center;
                }
              }
              .watch {
                color: red !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $smallMobileBreakPoint) {
  #curriculum-infor-wrapper {
    .course-curriculum-infor-wrapper {
      border: none;
      .ant-collapse-header {
        background-color: $gold;
        font-weight: bold;
        color: #FFFFFF;
      }
      .ant-collapse {
        border: none;
        margin-bottom: 2px;
      }
      .ant-collapse-content-box {
        padding: 16px 0px !important;
      }
      .term-course-curriculum-infor-wrapper {
        .ant-collapse-header {
          background-color: #DDDDDD;
          color: $darkgrey;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            .lesson-term-course-curriculum-infor-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0px 10px 16px 10px;
              border-bottom: 1px solid #DDDDDD;
              margin-top: 15px;
              .infor-lesson-term-course-curriculum-infor-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                .title-infor-lesson-term-course-curriculum-infor-wrapper {
                  font-size: 14px;
                  color: #707070;
                  margin-left: 8px;
                  font-family: 'Muli-Regular';
                }
              }
              .duration-infor-lesson-term-course-curriculum-infor-wrapper {
                font-size: 14px;
                color: #707070;
                margin-left: 4px;
                font-family: 'Muli-Regular';
              }
              .control-lesson-term-course-curriculum-infor-wrapper {
                button {
                  padding: 0px;
                  border: none;
                  text-align: center;
                  font-size: 14px;
                  background: none;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $mobileBreakPoint) {
  #curriculum-infor-wrapper {
    .course-curriculum-infor-wrapper {
      border: none;
      .ant-collapse-header {
        background-color: $gold;
        font-weight: bold;
        color: #FFFFFF;
      }
      .ant-collapse-content-box {
        padding: 16px 0px !important;
      }
      .ant-collapse {
        border: none;
        margin-bottom: 2px;
      }
      .term-course-curriculum-infor-wrapper {
        .ant-collapse-header {
          background-color: #DDDDDD;
          color: $darkgrey;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            .lesson-term-course-curriculum-infor-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding:0px 10px 16px 10px;
              border-bottom: 1px solid #DDDDDD;
              margin-top: 15px;
              .infor-lesson-term-course-curriculum-infor-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .title-infor-lesson-term-course-curriculum-infor-wrapper {
                  font-size: 14px;
                  color: #707070;
                  cursor: pointer;
                  margin-left: 8px;
                  font-family: 'Muli-Regular';
                }
              }
              .duration-infor-lesson-term-course-curriculum-infor-wrapper {
                font-size: 14px;
                color: #707070;
                margin-left: 4px;
                font-family: 'Muli-Regular';
              }
              .infor-lesson-term-course-curriculum-infor-wrapper-watch {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .title-infor-lesson-term-course-curriculum-infor-wrapper {
                  font-size: 14px;
                  color: red;
                  margin-left: 8px;
                }
                .control-lesson-term-course-curriculum-infor-wrapper {
                  button {
                    span {
                      svg {
                        color: red;
                      }
                    }
                  }
                }
              }
              .duration-infor-lesson-term-course-curriculum-infor-wrapper-watch {
                color: red;
              }
              .control-lesson-term-course-curriculum-infor-wrapper {
                button {
                  padding: 0px;
                  border: none;
                  text-align: center;
                  font-size: 14px;
                  background: none;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $mobileBreakPoint + 1) {
  #curriculum-infor-wrapper {
    .course-curriculum-infor-wrapper {
      border: none;
      .ant-collapse-header {
        background-color: $gold;
        font-weight: bold;
        color: #FFFFFF;
      }
      .ant-collapse {
        border: none;
        margin-bottom: 2px;
      }
      .ant-collapse-content-box {
        padding: 2px 0px !important;
      }
      .term-course-curriculum-infor-wrapper {
        .ant-collapse-header {
          background-color: #DDDDDD;
          color: $darkgrey;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            .lesson-term-course-curriculum-infor-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding:0px 10px 16px 10px;
              border-bottom: 1px solid #DDDDDD;
              margin-top: 15px;
              .infor-lesson-term-course-curriculum-infor-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .title-infor-lesson-term-course-curriculum-infor-wrapper {
                  font-size: 14px;
                  color: #707070;
                  cursor: pointer;
                  margin-left: 8px;
                  font-family: 'Muli-Regular';
                }
              }
              .duration-infor-lesson-term-course-curriculum-infor-wrapper {
                font-size: 14px;
                color: #707070;
                margin-left: 4px;
                font-family: 'Muli-Regular';
              }
              .infor-lesson-term-course-curriculum-infor-wrapper-watch {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .title-infor-lesson-term-course-curriculum-infor-wrapper {
                  font-size: 14px;
                  color: red;
                  margin-left: 8px;
                }
                .control-lesson-term-course-curriculum-infor-wrapper {
                  button {
                    span {
                      svg {
                        color: red;
                      }
                    }
                  }
                }
              }
              .duration-infor-lesson-term-course-curriculum-infor-wrapper-watch {
                color: red;
              }
              .control-lesson-term-course-curriculum-infor-wrapper {
                button {
                  padding: 0px;
                  border: none;
                  text-align: center;
                  font-size: 14px;
                  background: none;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}