@import '../../shared.scss';
@import '../../assets/font/font.scss';

$paddingLine: 0 0 15px 0;
$mobilePaddingLine: 0 0 10px 0;
$paddingRightHeader: 20px;
$walletPaddingContainer: 12px 0 12px 0;

.OrderInfoContainer {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 130px;
  }

  .Title {
    @extend %fontHeader;
    @extend %fontBold;
    padding-bottom: 15px;
  }

  .OrderInfo {
    @media only screen and (max-width: $mobileBreakPoint) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .CourseInfo {
      box-sizing: border-box;
      box-shadow: 10px 3px 30px rgba(213, 158, 41, 0.1);
      @media only screen and (max-width: $mobileBreakPoint) {
        padding: 10px 15px;
        margin-bottom: 15px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding: 20px;
        width: 640px;
        margin-bottom: 30px;
      }

      .CourseHeader {
        display: flex;
        align-items: center;

        @media only screen and (max-width: $mobileBreakPoint) {
          flex-direction: column;
          justify-content: space-between;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          flex-direction: row;
          justify-content: space-between;
        }

        .HeaderText {
          @extend %fontHeader;
          @extend %fontBold;

          @media only screen and (max-width: $mobileBreakPoint) {
            padding-top: 10px;
            padding-bottom: 10px;
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            padding-right: $paddingRightHeader;
          }
        }
        .Text {
          color: $brown;
          text-align: center;
          padding-bottom: 5px;
        }

        .LevelPrice {
          display: flex;
          align-items: center;
          flex-direction: row;

          @media only screen and (max-width: $mobileBreakPoint) {
            justify-content: space-between;
            width: 100%;
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            justify-content: space-around;
          }

          .LevelContainer {
            padding-right: $paddingRightHeader;
            .Level {
              background-color: $gold;
              border-radius: 28px;
              color: white;
              @extend %fontBold;
              padding: 5px 15px;

              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 12px;
              }

              @media only screen and (min-width: $mobileBreakPoint + 1) {
              }
            }
          }
          .PriceContainer {
            margin-left: 10px;
            .NumberContainer {
              // background-color: $gold;
              color: white;
              transform: skewX(-30deg);
              padding: 5px 10px;

              .PriceNumber {
                display: inline-block;
                transform: skewX(30deg);

                span {
                  @extend %fontExtraBold;
                  padding-right: 5px;
                }
              }
              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 14px;
                sup {
                  font-size: 10px;
                }
              }
              @media only screen and (min-width: $mobileBreakPoint + 1) {
                font-size: 20px;

                sup {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .Info {
        padding-top: 15px;
        margin-top: 15px;
        border-top: 1px solid $grey;

        .Container {
          display: flex;
          flex-direction: row;
          align-items: center;

          @media only screen and (max-width: $mobileBreakPoint) {
            padding: $mobilePaddingLine;
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            padding: $paddingLine;
          }

          img {
            padding-right: 10px;
          }
        }
        span {
          @extend %fontExtraBold;
          color: $grey;

          @media only screen and (max-width: $mobileBreakPoint) {
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }

    .WalletInfoContainer {
      background: linear-gradient(to right, $darkgrey 50%, #fff 0%)
          top/12px 1px repeat-x,
        /* top */ linear-gradient($darkgrey 50%, #fff 0%) right/1px
          12px repeat-y,
        /* right */ linear-gradient(to right, $darkgrey 50%, #fff 0%)
          bottom/12px 1px repeat-x,
        /* bottom */ linear-gradient($darkgrey 50%, #fff 0%) left/1px
          12px repeat-y; /* left */

      box-sizing: border-box;
      box-shadow: 10px 3px 30px rgba(213, 158, 41, 0.1);

      @media only screen and (max-width: $mobileBreakPoint) {
        margin-bottom: 15px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        width: 332px;
        margin-bottom: 30px;
      }

      .WalletTitle {
        background: linear-gradient(to right, $darkgrey 50%, $gold 0%)
            top/12px 1px repeat-x,
          /* top */ linear-gradient($darkgrey 50%, $gold 0%) right/1px
            12px repeat-y,
          /* right */
            linear-gradient(to right, $darkgrey 50%, $gold 0%)
            bottom/12px 1px repeat-x,
          /* bottom */ linear-gradient($darkgrey 50%, $gold 0%)
            left/1px 12px repeat-y; /* left */
        background-color: $gold;
        text-align: center;
        color: white;
        @extend %fontHeader;
        @extend %fontBold;
        padding-top: 7px;
        padding-bottom: 7px;
      }
      .WalletInfo {
        padding-right: 15px;
        padding-left: 15px;

        .WalletInfoItems {
          :last-child {
            border: none;
          }
          padding-bottom: 15px;
        }

        .Container {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: $walletPaddingContainer;
          border-bottom: 1px solid $grey;

          .Text {
            width: 70%;
          }
          .Number {
            width: 35%;
            text-align: right;
          }
          .Currency {
            text-align: right;
            width: 10%;
            color: black;
          }

          &.Remaining {
            .Number {
              color: $grey;
              @include fontBold();
              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 17px;
              }

              @media only screen and (min-width: $mobileBreakPoint + 1) {
                font-size: 25px;
              }
            }
          }
          &.PendingOrder {
            .Number {
              color: black;
              @include fontBold();
              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 17px;
              }

              @media only screen and (min-width: $mobileBreakPoint + 1) {
                font-size: 25px;
              }
            }
            .ant-checkbox-wrapper {
              margin-right: 5px;
              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 1px solid#d59e29;
                  border-radius: 2px;
                }
              }
              .ant-checkbox.ant-checkbox-checked {
                &:after {
                  border: none;
                }
                .ant-checkbox-inner {
                  background-color: #d59e29;
                }
              }
            }
          }
          &.AddInsurance {
            flex-direction: column;
            display: flex;
            .Text {
              width: 100%;
              color: #707070;
              @extend %fontSemiBold;
              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 14px;
              }

              @media only screen and (min-width: $mobileBreakPoint + 1) {
                font-size: 16px;
              }
              .ant-checkbox-wrapper {
                margin-right: 5px;
                .ant-checkbox {
                  .ant-checkbox-inner {
                    border: 1px solid#d59e29;
                    border-radius: 2px;
                  }
                }
                .ant-checkbox.ant-checkbox-checked {
                  &:after {
                    border: none;
                  }
                  .ant-checkbox-inner {
                    background-color: #d59e29;
                  }
                }
              }
            }
            .FeeInsurance {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
            }
            .NumberContainer {
              // background-color: #d59e29;
              color: white;
              transform: skewX(-30deg);
              padding: 5px 10px;
              margin-right: 10px;
              .PriceNumber {
                display: inline-block;
                transform: skewX(30deg);
                @extend %fontExtraBold;
                > span {
                  padding-right: 5px;
                }
                .Currency {
                  color: #fff;
                }
              }
            }
          }
          &.Missing {
            .Number {
              color: $gold;
              @include fontBold();
              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 17px;
              }

              @media only screen and (min-width: $mobileBreakPoint + 1) {
                font-size: 25px;
              }
            }
          }
        }
        .CTAButton {
          @extend %fontBold;
          padding-top: 7px;
          padding-bottom: 7px;
          color: white;
          background-color: $brown;
          text-align: center;
          width: 85%;
          margin: auto;
          cursor: pointer;

          @media only screen and (max-width: $mobileBreakPoint) {
            margin-bottom: 10px;
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .TextNotice {
    white-space: pre-line;
  }
}

.pay_button {
  color: white;
  background-color: #d59e29;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px 0;
  margin-bottom: 10px;
  cursor: pointer;
  width: 20vw;
  font-weight: bold;
  align-self: center;
  @media only screen and (min-width: 414px) {
    width: 200px;
  }
}
.paypal-container-row {
  overflow: hidden;
}

.paypal-button-row {
  margin-top: -45px;
}
