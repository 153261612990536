@import '../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  #english-courses-list-wrap {
    background-image: url('../../../../assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px
  }
  #title-english-courses {
    width: 100%;
    text-align: center;
    color: $gold;
    font-size: 20px;
    font-weight: bold;
  }
  #bonus-title {
    width: 100%;
    text-align: center;
    color: $grey;
    font-size: 12px;
    font-family: 'Muli-Medium' !important;
  }
  .english-course-list-wrapper {
    width: 100%;
    margin-top: 1.88vw;
    padding: 0px 0px;
    .anticon-right-circle {
      right: 10px;
      top: 135px;
      font-size: 20px;
      svg {
        width: 20px;
        color: #FFFFFF;
        background-color: $gold;
        border-radius: 50%;
      }
    }
    .anticon-left-circle {
      left: 10px;
      top: 135px;
      font-size: 20px;
      z-index: 2;
      svg {
        width: 20px;
        color: #FFFFFF;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $mobileBreakPoint) {
  #english-courses-list-wrap {
    background-image: url('../../../../assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px
  }
  #title-english-courses {
    width: 100%;
    text-align: center;
    color: $brown;
    font-size: 30px;
    font-weight: bold;
  }
  #bonus-title {
    width: 100%;
    text-align: center;
    color: $grey;
    font-size: 18px;
    font-family: 'Muli-Medium' !important;
  }
  .english-course-list-wrapper {
    width: 100%;
    margin-top: 1.88vw;
    padding: 0px 120px;
    .anticon-right-circle {
      right: 50px;
      top: 135px;
      font-size: 40px;
      svg {
        width: 40px;
        color: #FFFFFF;
        background-color: $gold;
        border-radius: 50%;
      }
    }
    .anticon-left-circle {
      left: 50px;
      top: 135px;
      font-size: 40px;
      svg {
        width: 40px;
        color: #FFFFFF;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $mobileBreakPoint + 1) {
  #english-courses-list-wrap {
    background-image: url('../../../../assets/images/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px
  }
  #title-english-courses {
    width: 100%;
    text-align: center;
    color: $brown;
    font-size: 30px;
    font-weight: bold;
  }
  #bonus-title {
    width: 100%;
    text-align: center;
    color: $grey;
    font-size: 18px;
    font-family: 'Muli-Medium' !important;
  }
  .english-course-list-wrapper {
    width: 100%;
    margin-top: 1.88vw;
    padding: 0px 120px;
    .anticon-right-circle {
      right: 50px;
      top: 135px;
      font-size: 40px;
      svg {
        width: 40px;
        color: #FFFFFF;
        background-color: $gold;
        border-radius: 50%;
      }
    }
    .anticon-left-circle {
      left: 50px;
      top: 135px;
      font-size: 40px;
      svg {
        width: 40px;
        color: #FFFFFF;
      }
    }
  }
}