@import '../../shared.scss';
@import '../../assets/font/font.scss';

%LoginHeader {
  @extend %fontExtraBold;
  color: $brown;
  @extend %fontHeader;
  text-align: center;
  padding-bottom: 5px;
}

%styleLoginSelected {
  border-bottom: 3px solid $brown;
}

%styleButton {
  margin-left: auto;
  margin-right: auto;
  background-color: $brown;
  color: white;
  @extend %fontBold;
  border-radius: 10px;
  cursor: pointer;

  @media only screen and (max-width: $mobileBreakPoint) {
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding: 12px 0;
    text-align: center;
    margin-top: 25px;
  }
}
.LoginOverlay {
  position: sticky;
  display: none;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;

  .LoginPopup {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    overflow: visible;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(213, 158, 41, 0.2);

    @media only screen and (max-width: $mobileBreakPoint) {
      width: 80%;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      width: 850px;
      height: 450px;
      margin: auto;
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 25px 85px;
    }

    .CancelButton {
      $size: 30px;
      position: absolute;
      top: -$size / 2;
      right: -$size / 2;
      width: $size;
      height: $size;
    }

    .Container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .Signin,
      .Signup {
        width: 45%;
        .Title {
          box-sizing: border-box;
          text-align: center;

          @media only screen and (max-width: $mobileBreakPoint) {
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            padding-top: 25px;
            margin-bottom: 25px;
          }

          span {
            @extend %LoginHeader;
          }

          &:hover {
            @extend %styleLoginSelected;
          }
        }
      }

      .Signin {
        .Email,
        .Password {
          display: flex;
          flex-direction: column;

          span {
            @extend %fontExtraBold;
            color: $grey;
          }

          input {
            border: none;
            margin-top: 7px;
            margin-bottom: 15px;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
            height: 50px;
            @extend %fontSemiBold;
            color: $darkgrey;
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
        .ForgotPassword {
          font-style: italic;
        }

        .SigninButton {
          @extend %styleButton;
        }
      }
    }
  }
}
