@import '../../shared.scss';

// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;


p {
  font-family: 'Poppins', sans-serif;
}

.card_container {
  width: 254px;
  height: 102px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card_header {
  color: #B97A2A;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
}

.card_content {
  text-align: center;
}

@media only screen and (max-width: $smallMobileBreakPoint) {
  .card_content {
    margin: auto;
    text-align: center;
  }

  .card_header {
    color: #B97A2A;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    margin-bottom: 0px;
  }

  .card_container {
    width: 184px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}