@import './shared.scss';

.App {
  color: $darkgrey;
  -webkit-backface-visibility: hidden;
  transform: perspective(0px);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Content {
  max-width: $appMaxWidth;
  margin: auto;
}

.css-0 div {
  background-color: #ffffff;
}

._loading_overlay_overlay {
  position: fixed !important;
  z-index: 98 !important;
}

.Toastify__toast-container {
  position: fixed !important;
  z-index: 999 !important;
}

input[type='text'],
input[type='number'],
input[type='password'] {
  border: 1px solid $lightgrey !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16) !important;
}

input[type='submit'] {
  border: none;
}

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .ant-modal-root {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.85);
    }
    .popup-modal-wrapper {
      height: auto !important;
      .ant-modal-content {
        background: transparent;
        height: 100% !important;
        .ant-modal-close {
          width: 18px;
          height: 18px;
          background-color: #FFFFFF;
          border-radius: 50%;
          right: 0px;
          top: -30px;
          .ant-modal-close-x {
            width: 100%;
            height: 100%;
            line-height: 13px;
          }
        }
        .ant-modal-header {
          background: transparent;
          padding-left: 0px;
          .ant-modal-title {
            color: #FFFFFF;
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }
        }
        .ant-modal-body {
          padding-top: 0px;
        }
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  .ant-modal-root {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.85);
    }
    .popup-modal-wrapper {
      height: auto !important;
      .ant-modal-content {
        background: transparent;
        height: 100% !important;
        .ant-modal-close {
          width: 36px;
          height: 36px;
          background-color: #FFFFFF;
          border-radius: 50%;
          right: -40px;
          top: -40px;
          .ant-modal-close-x {
            width: 100%;
            height: 100%;
            line-height: 33px;
          }
        }
        .ant-modal-header {
          background: transparent;
          padding-left: 0px;
          .ant-modal-title {
            color: #FFFFFF;
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }
        }
        .ant-modal-body {
          padding-top: 0px;
        }
      }
    }
  } 
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  .ant-modal-root {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.85);
    }
    .popup-modal-wrapper {
      width: 954px !important;
      height: auto !important;
      transform-origin: 954px 539px !important;
      .ant-modal-content {
        background: transparent;
        height: 100% !important;
        .ant-modal-close {
          width: 42px;
          height: 42px;
          background-color: #FFFFFF;
          border-radius: 50%;
          right: -10px;
          top: -50px;
          .ant-modal-close-x {
            width: 100%;
            height: 100%;
            line-height: 37px;
          }
        }
        .ant-modal-header {
          background: transparent;
          padding-left: 0px;
          .ant-modal-title {
            color: #FFFFFF;
            font-size: 25px;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }
        }
        .ant-modal-body {
          padding-top: 0px;
        }
      }
    }
  }
}