@import '../../shared.scss';
@import '../../assets/font/font.scss';

.ForgotPasswordOverlay {
  position: sticky;
  display: flex;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;

  .ForgotPasswordPopup {
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    overflow: visible;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(213, 158, 41, 0.2);

    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $mobileBreakPoint) {
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 15px;
      width: 80%;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      width: 850px;
      height: 450px;
      margin: auto;
      margin-bottom: 10px;
      margin-top: 10px;
      padding: 40px 45px;
    }

    .CancelButton {
      img {
        position: absolute;
        cursor: pointer;
        @media only screen and (max-width: $mobileBreakPoint) {
          $size: 20px;
          top: -$size / 2;
          right: -$size / 2;
          width: $size;
          height: $size;
        }
        @media only screen and (min-width: $mobileBreakPoint + 1) {
          $size: 32px;
          top: -$size / 2;
          right: -$size / 2;
          width: $size;
          height: $size;
        }
      }
    }

    .Title {
      @extend %fontBold;
      @extend %fontHeader;
      color: grey;
      text-align: center;
      border-bottom: 1px solid $darkgrey;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding-bottom: 10px;
        margin-bottom: 20px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-bottom: 25px;
        margin-bottom: 40px;
      }
    }

    input {
      box-sizing: border-box;
      border: none;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
      color: $darkgrey;
      font-size: 14px;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding: 5px;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        width: 372px;
        height: 52px;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 45px;
      }
    }
    ::placeholder {
      font-style: italic;
      font-size: 10px;
      @media only screen and (max-width: $mobileBreakPoint) {
        font-size: 8px;
      }
    }

    .SendButton {
      margin-bottom: 5px;
      margin-top: 5px;
      span {
        color: white;
        @extend %fontBold;
        background-color: $brown;
        box-sizing: border-box;
        border-radius: 10px;

        @media only screen and (max-width: $mobileBreakPoint) {
          padding: 5px 25px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          padding: 12px 90px;
        }
      }
    }
  }
}
