@import '../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  #video-category-list-wrapper {
    margin-bottom: 56px;
  }
  //VIDEO MODAL
  .ant-modal-root {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.85);
    }
    .video-modal-wrapper {
      // width: 954px !important;
      height: auto !important;
      // transform-origin: 954px 539px !important;
      .ant-modal-content {
        background: transparent;
        height: 100% !important;
        .ant-modal-close {
          width: 36px;
          height: 36px;
          background-color: #FFFFFF;
          border-radius: 50%;
          right: 0px;
          top: 20px;
          .ant-modal-close-x {
            width: 100%;
            height: 100%;
            line-height: 33px;
          }
        }
        .ant-modal-header {
          background: transparent;
          padding-left: 0px;
          .ant-modal-title {
            color: #FFFFFF;
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }
        }
        .ant-modal-body {
          padding-top: 0px;
          .react-player-video-modal-wrapper {
            width: auto !important;
            // height: 539px !important;
          }
        }
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  //VIDEO MODAL
  .ant-modal-root {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.85);
    }
    .video-modal-wrapper {
      // width: 954px !important;
      height: auto !important;
      // transform-origin: 954px 539px !important;
      .ant-modal-content {
        background: transparent;
        height: 100% !important;
        .ant-modal-close {
          width: 36px;
          height: 36px;
          background-color: #FFFFFF;
          border-radius: 50%;
          right: 0px;
          top: 20px;
          .ant-modal-close-x {
            width: 100%;
            height: 100%;
            line-height: 33px;
          }
        }
        .ant-modal-header {
          background: transparent;
          padding-left: 0px;
          .ant-modal-title {
            color: #FFFFFF;
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }
        }
        .ant-modal-body {
          padding-top: 0px;
          .react-player-video-modal-wrapper {
            width: auto !important;
            // height: 539px !important;
          }
        }
      }
    }
  } 
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  // VIDEO CATEGORY LIST
  #video-category-list-wrapper {
    width: 1089px;
    max-width: 1089px;
    margin: auto;
    margin-bottom: 117px;
  }
  //VIDEO MODAL
  .ant-modal-root {
    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.85);
    }
    .video-modal-wrapper {
      width: 954px !important;
      height: auto !important;
      transform-origin: 954px 539px !important;
      .ant-modal-content {
        background: transparent;
        height: 100% !important;
        .ant-modal-close {
          width: 42px;
          height: 42px;
          background-color: #FFFFFF;
          border-radius: 50%;
          right: -70px;
          top: 5px;
          .ant-modal-close-x {
            width: 100%;
            height: 100%;
            line-height: 37px;
          }
        }
        .ant-modal-header {
          background: transparent;
          padding-left: 0px;
          .ant-modal-title {
            color: #FFFFFF;
            font-size: 25px;
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }
        }
        .ant-modal-body {
          padding-top: 0px;
          .react-player-video-modal-wrapper {
            width: auto !important;
            height: 539px !important;
          }
        }
      }
    }
  }
}
