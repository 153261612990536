@font-face {
  font-family: 'Muli-Regular';
  src: url('./Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli-Bold';
  src: url('./Muli-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli-Italic';
  src: url('./Muli-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli-Medium';
  src: url('./Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli-SemiBold';
  src: url('./Muli-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli-ExtraBold';
  src: url('./Muli-ExtraBold.ttf') format('truetype');
}
