@import '../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  #english-course-view-wrapper {
    margin-top: 8.33vw;
    margin-bottom: 50px;
    padding: 0px 0px;
    h1 {
      font-size: 20px;
      font-weight: bold;
      font-family: 'Muli';
      color: #707070;
      margin: 10px 25px 25px;
    }
    #content-course-english-course-view-wrapper {
      width: 100%;
      margin: auto;
      text-align: left;
      #control-content-course-english-course-view-wrapper {
        #content-control-content-course-english-course-view-wrapper {
          width: 100%;
          height: auto;
          border: solid 1px #70707070;
          img {
            width: 100%;
          }
          iframe {
            width: 100%;
            height: 175px;
          }
        }
        .tab-info-content-course-english-course-view-wrapper {
          margin: 10px 25px 25px;
          .ant-tabs-nav {
            // box-shadow: 2px 2px rgba($color: #000000, $alpha: 0.3);
            border: solid 0.5px rgba($color: $darkgrey, $alpha: 0.2);
            border-radius: 100px;
            padding: 5px 5px;
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  width: 100px;
                  border-radius: 25px;
                  padding: 0;
                  .ant-tabs-tab-btn {
                    font-size: 10px;
                    margin: auto;
                  }
                }
                .ant-tabs-tab-active {
                  background-color: $gold;
                  height: 30px;
                  .ant-tabs-tab-btn {
                    color: #FFFFFF;
                    font-weight: bold;
                  }
                  .ant-tabs-tab-btn:hover {
                    color: #FFFFFF;
                  }
                }
                .ant-tabs-ink-bar {
                  display: none;
                }
              }
            }
          }
          .ant-tabs-nav::before {
            display: none;
          }
          .ant-tabs-content-holder {
            margin-top: 20px;
            padding: 5px 5px;
            border: solid 1px #DDDDDD;
            text-align: left;
            // border-bottom: none;
            #lesson-list-tab-info-content-course-english-course-view-wrapper {
              width: 100%;
              padding: 0px 10px;
              margin-top: 12px;
              h5 {
                font-size: 14px;
                font-weight: bold;
                font-family: Poppins;
                color: #707070;
              }
              .item-lesson-list-item-wrapper {
                width: 220px;
                height: 30px;
                margin: auto;
                margin-bottom: 10px;
                background-color: $gold;
                color: #FFFFFF;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        #course-combo-control-content-course-english-course-view-wrapper {
          margin: 10px 25px 25px;
          h3 {
            font-size: 14px;
            font-weight: bold;
            font-family: Poppins;
            color: #707070;
            width: 100%;
            text-align: left;
            border-bottom: 1px $gold solid;
            padding-bottom: 10px;
          }
        }
      }
      #data-board-content-course-english-course-view-wrapper {
        // margin-left: 10px;
        width: 270px;
        margin: auto;
      }
    }
    // #related-courses-english-course-view-wrapper {
    //   width: 89.43vw;
    //   height: 38.75vw;
    //   display: flex;
    //   flex-direction: column;
    //   flex-flow: column;
    //   justify-content: space-between;
    //   margin-top: 11.77vw;
    //   padding: 2.29vw 0px;
    //   border-top: 1px solid #707070;
    //   border-bottom: 1px solid #707070;
    //   h2 {
    //     color: #707070;
    //     font-size: 4.38vw;
    //     font-weight: bold;
    //     font-family: 'Muli';
    //     text-align: center;
    //   }
    // }
  }
  #suggest-courses-english-course-view-wrapper {
    margin-top: 6.77vw;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    margin-bottom: 10.42vw;
    h2 {
      color: #707070;
      font-size: 20px;
      font-family: 'Muli';
      font-weight: bold;
      text-align: center;
    }
    .english-course-list-wrapper {
      width: 100%;
      margin-top: 1.88vw;
      padding: 0px 30px;
      .anticon-right-circle {
        right: 50px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          color: #FFFFFF;
          background-color: $gold;
          border-radius: 50%;
        }
      }
      .anticon-left-circle {
        left: 30px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          color: #FFFFFF;
          background-color: $gold;
          border-radius: 50%;
        }
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $mobileBreakPoint) {
  #english-course-view-wrapper {
    margin-top: 8.33vw;
    margin-bottom: 50px;
    padding: 0px 0px;
    h1 {
      font-size: 20px;
      font-weight: bold;
      font-family: 'Muli';
      color: #707070;
      margin: 10px 25px 25px;
    }
    #content-course-english-course-view-wrapper {
      width: 100%;
      margin: auto;
      text-align: left;
      #control-content-course-english-course-view-wrapper {
        #content-control-content-course-english-course-view-wrapper {
          width: 100%;
          height: auto;
          img {
            width: 100%;
          }
          iframe {
            width: 100%;
            height: 335px;
          }
        }
        .tab-info-content-course-english-course-view-wrapper {
          margin: 10px 25px 25px;
          .ant-tabs-nav {
            // box-shadow: 2px 2px rgba($color: #000000, $alpha: 0.3);
            border: solid 0.5px rgba($color: $darkgrey, $alpha: 0.2);
            border-radius: 100px;
            padding: 5px 5px;
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  width: 100px;
                  border-radius: 25px;
                  padding: 0;
                  .ant-tabs-tab-btn {
                    font-size: 10px;
                    margin: auto;
                  }
                }
                .ant-tabs-tab-active {
                  background-color: $gold;
                  height: 30px;
                  .ant-tabs-tab-btn {
                    color: #FFFFFF;
                    font-weight: bold;
                  }
                  .ant-tabs-tab-btn:hover {
                    color: #FFFFFF;
                  }
                }
                .ant-tabs-ink-bar {
                  display: none;
                }
              }
            }
          }
          .ant-tabs-nav::before {
            display: none;
          }
          .ant-tabs-content-holder {
            margin-top: 20px;
            padding: 5px 5px;
            border: solid 1px #DDDDDD;
            text-align: left;
            // border-bottom: none;
            #lesson-list-tab-info-content-course-english-course-view-wrapper {
              width: 100%;
              padding: 0px 10px;
              margin-top: 12px;
              h5 {
                font-size: 14px;
                font-weight: bold;
                font-family: Poppins;
                color: #707070;
              }
              .item-lesson-list-item-wrapper {
                width: 300px;
                height: 30px;
                margin: auto;
                margin-bottom: 10px;
                background-color: $gold;
                color: #FFFFFF;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        #course-combo-control-content-course-english-course-view-wrapper {
          margin: 10px 25px 25px;
          h3 {
            font-size: 14px;
            font-weight: bold;
            font-family: Poppins;
            color: #707070;
            width: 100%;
            text-align: left;
            border-bottom: 1px $gold solid;
            padding-bottom: 10px;
          }
        }
      }
      #data-board-content-course-english-course-view-wrapper {
        // margin-left: 10px;
        width: 270px;
        margin: auto;
      }
    }
    // #related-courses-english-course-view-wrapper {
    //   width: 89.43vw;
    //   height: 38.75vw;
    //   display: flex;
    //   flex-direction: column;
    //   flex-flow: column;
    //   justify-content: space-between;
    //   margin-top: 11.77vw;
    //   padding: 2.29vw 0px;
    //   border-top: 1px solid #707070;
    //   border-bottom: 1px solid #707070;
    //   h2 {
    //     color: #707070;
    //     font-size: 4.38vw;
    //     font-weight: bold;
    //     font-family: 'Muli';
    //     text-align: center;
    //   }
    // }
  }
  #suggest-courses-english-course-view-wrapper {
    margin-top: 6.77vw;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    margin-bottom: 10.42vw;
    h2 {
      color: #707070;
      font-size: 30px;
      font-weight: bold;
      font-family: 'Muli';
      text-align: center;
    }
    .english-course-list-wrapper {
      width: 100%;
      padding: 0px 30px;
      .anticon-right-circle {
        right: 50px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          color: #FFFFFF;
          background-color: $gold;
          border-radius: 50%;
        }
      }
      .anticon-left-circle {
        left: 30px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          color: #FFFFFF;
          background-color: $gold;
          border-radius: 50%;
        }
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $mobileBreakPoint + 1) {
  #english-course-view-wrapper {
    max-width: 1089px;
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 50px;
    // padding: 0px 100px;
    h1 {
      font-size: 20px;
      font-weight: bold;
      font-family: 'Muli';
      color: #707070;
    }
    #content-course-english-course-view-wrapper {
      width: 100%;
      margin: auto;
      text-align: left;
      #control-content-course-english-course-view-wrapper {
        #content-control-content-course-english-course-view-wrapper {
          width: 100%;
          max-width: 720px;
          height: auto;
          img {
            width: 100%;
            height: 100%;
          }
          iframe {
            width: 100%;
            height: 335px;
          }
        }
        .tab-info-content-course-english-course-view-wrapper {
          width: 100%;
          max-width: 720px;
          margin-top: 16px;
          .ant-tabs-nav {
            border: solid 0.5px rgba($color: $darkgrey, $alpha: 0.2);
            border-radius: 100px;
            padding: 7px 7px;
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  width: 198px;
                  height: 54px;
                  border-radius: 42px;
                  .ant-tabs-tab-btn {
                    font-size: 12px;
                    margin: auto;
                  }
                }
                .ant-tabs-tab-active {
                  background-color: $gold;
                  .ant-tabs-tab-btn {
                    color: #FFFFFF;
                    font-weight: bold;
                  }
                  .ant-tabs-tab-btn:hover {
                    color: #FFFFFF;
                  }
                }
                .ant-tabs-ink-bar {
                  display: none;
                }
              }
            }
          }
          .ant-tabs-nav::before {
            display: none;
          }
          .ant-tabs-content-holder {
            margin-top: 16px;
            border: solid 1px #DDDDDD;
            padding: 10px 30px;
            text-align: left;
            #lesson-list-tab-info-content-course-english-course-view-wrapper {
              width: 100%;
              padding-left: 19px;
              margin-top: 12px;
              h5 {
                font-size: 14px;
                font-weight: bold;
                font-family: Poppins;
                color: #707070;
              }
              .item-lesson-list-item-wrapper {
                width: 284px;
                height: 30px;
                margin: auto;
                margin-bottom: 10px;
                background-color: $gold;
                color: #FFFFFF;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
        #course-combo-control-content-course-english-course-view-wrapper {
          width: 100%;
          margin-top: 37px;
          h3 {
            font-size: 14px;
            font-weight: bold;
            font-family: Poppins;
            color: #707070;
            width: 100%;
            text-align: left;
            border-bottom: 1px $gold solid;
            padding-bottom: 10px;
          }
        }
      }
      #data-board-content-course-english-course-view-wrapper {
        margin-left: 2.24vw;
      }
    }
    #related-courses-english-course-view-wrapper {
      width: 99.71vw;
      height: 38.75vw;
      display: flex;
      flex-direction: column;
      flex-flow: column;
      justify-content: space-between;
      margin-top: 11.77vw;
      padding: 2.29vw 0px;
      border-top: 1px solid #707070;
      border-bottom: 1px solid #707070;
      h2 {
        color: #707070;
        font-size: 1.56vw;
        font-weight: bold;
        font-family: 'Muli';
        text-align: center;
      }
    }
  }
  #suggest-courses-english-course-view-wrapper {
    margin-top: 50px;
    margin-bottom: 100px;
    width: 100%;
    h2 {
      width: 100%;
      text-align: center;
      color: #707070;
      font-size: 30px;
      font-family: 'Muli';
      font-weight: bold;
    }
    .ant-carousel {
      width: 100%;
    }
    .english-course-list-wrapper {
      width: 100%;
      margin-top: 1.88vw;
      padding: 0px 120px;
      .anticon-right-circle {
        right: 50px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          color: #FFFFFF;
          background-color: $gold;
          border-radius: 50%;
        }
      }
      .anticon-left-circle {
        left: 50px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          background-color: $gold;
          color: #FFFFFF;
          border-radius: 50%;
        }
      }
    }
  }
}

.AudioBookContainerEnglish {
  @media only screen and (max-width: $mobileBreakPoint ) {
    width: 70%;
    margin: auto;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    max-width: 362px;
  }
}