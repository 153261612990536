@import '../../shared.scss';

.CourseTitle {
  text-align: center;
  line-height: 1.3;
  min-width: 80%;

  .Title {
    @extend %fontBold;
    color: $gold;
  }
  .Note {
    @extend %fontMedium;
    padding-left: 10px;
    padding-right: 10px;
  }

  .CourseNote {
    @extend %fontMedium;
    font-size: 12px;
  }

  @media only screen and (max-width: $smallMobileBreakPoint) {
    padding-top: 10px;
    padding-bottom: 10px;

    .Title {
      font-size: 12px;
    }
    .Note {
      font-size: 7px;
    }
  }

  @media only screen and (min-width: $mobileBreakPoint) {
    padding: 45px 0 25px 0;

    .Title {
      font-size: 30px;
    }
    .Note {
      font-size: 11px;
    }
    .CourseNote {
      font-size: 18px;
    }
  }
}

.CourseCardContainer {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-bottom: 50px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-bottom: 130px;
  }

  .ant-carousel {
    height: 360px;
  }

  .CourseBackground {
    @media only screen and (max-width: $mobileBreakPoint) {
      width: 100%;
      height: 210px;
      margin-bottom: -170px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      width: 100%;
      height: 370px;
      margin-bottom: -285px;
    }
  }

  .CourseCards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow-x: scroll;
  }
}
