@import '../../shared.scss';
@import '../../assets/font/font.scss';

.ContactPage {
  font-family: 'Open Sans', sans-serif;
  text-align: justify;

  h5 {
    margin-top: 10px;
  }

  h6 {
    margin-top: 5px;
  }

  @media only screen and (max-width: $mobileBreakPoint) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 50px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-top: 150px;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 130px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: $darkblue;
    padding-bottom: 5px;
    border-bottom: 1px solid $lightgrey;
    @extend %fontHeader;

    @media only screen and (max-width: $mobileBreakPoint) {
      margin-bottom: 20px;
      img {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      margin-bottom: 40px;
      img {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .ContentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap-reverse;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding-bottom: 20px;
    }
    @media only screen and (min-width: $mobileBreakPoint + 1) {
      padding-bottom: 40px;
    }

    .ContactInfo {
      .Name,
      .Email,
      .Phone,
      .Text {
        @extend %fontSemiBold;
        color: black;
      }

      .Name {
        padding-top: 25px;
      }

      input,
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: white;
        border: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        @extend %fontBold;
        color: $darkgrey;
        @media only screen and (max-width: $mobileBreakPoint) {
          padding-left: 10px;
          padding-right: 10px;
          height: 30px;
          width: 100%;
          align-self: center;
          margin-top: 6px;
          margin-bottom: 15px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          padding-left: 22px;
          padding-right: 22px;
          height: 50px;
          width: 334px;
          margin-top: 6px;
          margin-bottom: 25px;
        }
      }

      select {
        background: url(../../assets/images/icon_grey_arrow_down.svg)
          96% / 5% no-repeat white;
        background-position: right;
      }

      .Text textarea {
        height: 135px;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: white;
        border: none;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        margin-top: 6px;
        padding: 20px;
      }
    }

    ::placeholder {
      @extend %fontSmallText;
      font-style: italic;
      color: $lightgrey;
    }

    .Note {
      color: black;
      @media only screen and (max-width: $mobileBreakPoint) {
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        font-size: 12px;
      }
    }
  }

  .LogoContainer {
    background-image: url(../../assets/images/logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;

    @media only screen and (max-width: $mobileBreakPoint) {
      width: 100%;
      max-width: 300px;
      height: 240px;
      background-size: cover;

      padding-top: 10px;
      padding-left: 15px;
      margin-bottom: 25px;
      margin-top: 25px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      width: 426px;
      height: 240px;
      box-sizing: border-box;
      padding-top: 5px;
      padding-left: 25px;
    }
  }

  .SendContact {
    box-sizing: border-box;
    display: inline;
    background-color: $brown;
    color: white;
    @extend %fontSemiBold;
    border-radius: 10px;
    cursor: pointer;

    @media only screen and (max-width: $mobileBreakPoint) {
      font-size: 12px;
      padding: 5px 20px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      font-size: 16px;
      padding: 10px 40px;
    }
  }
}
