@import '../../shared.scss';

.CourseDetailPage {
  .CourseInfoLevelContainer {
    display: flex;
    border-bottom: 1px solid $gold;
    @media only screen and (max-width: $mobileBreakPoint) {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 20px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      flex-direction: row;
      justify-content: space-between;
      max-width: 1089px;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: $mobileBreakPoint) {
    h2 {
      margin-top: 20px;
      width: 100%;
      text-align: center;
      color: #707070;
      font-size: 30px;
      font-weight: bold;
    }
    .english-course-list-wrapper {
      width: 100%;
      margin-top: 1.88vw;
      padding: 0px 70px;
      .anticon-right-circle {
        right: 25px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          color: #FFFFFF;
          background-color: $gold;
          border-radius: 50%;
        }
      }
      .anticon-left-circle {
        left: 25px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          background-color: $gold;
          color: #FFFFFF;
          border-radius: 50%;
        }
      }
    }
  }
  @media only screen and (min-width: $mobileBreakPoint + 1) {
    h2 {
      margin-top: 20px;
      width: 100%;
      text-align: center;
      color: #707070;
      font-size: 30px;
      font-weight: bold;
    }
    .english-course-list-wrapper {
      width: 100%;
      margin-top: 1.88vw;
      padding: 0px 120px;
      .anticon-right-circle {
        right: 50px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          color: #FFFFFF;
          background-color: $gold;
          border-radius: 50%;
        }
      }
      .anticon-left-circle {
        left: 50px;
        top: 135px;
        font-size: 40px;
        svg {
          width: 40px;
          background-color: $gold;
          color: #FFFFFF;
          border-radius: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: $smallMobileBreakPoint) {
  #suggess-course-title {
    font-size: 20px;
    font-family: 'Muli';
    font-weight: bold;
  }
}

@media screen and (min-width: $mobileBreakPoint) {
  #suggess-course-title {
    font-size: 30px;
    font-family: 'Muli';
    font-weight: bold;
  }
}

.AudioPriceCardContainer {
  min-width: 300px;
  .Wrap {
    max-width: 362px;
  }
}