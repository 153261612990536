@import '../../shared.scss';

.CourseCard {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  position: relative;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  width: 255px;

  @media only screen and (max-width: $mobileBreakPoint) {
    min-width: 180px;
    margin-bottom: 5px;
    smin-height: 240px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    min-width: 240px;
    max-width: 250px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 100%;
  }

  .Level {
    position: absolute;
    border: 1px solid $gold;
    @extend %fontSemiBold;
    color: white;
    background-color: $gold;
    border-radius: 28px;

    @media only screen and (max-width: $mobileBreakPoint) {
      top: 5px;
      left: 5px;
      padding: 5px;
      font-size: 12px;
      font-size: 10px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      top: 10px;
      left: 20px;
      padding: 5px 10px;
      font-size: 12px;
    }
  }

  .HaveInsurance {
    position: absolute;
    border: 1px solid $gold;
    @extend %fontSemiBold;
    color: white;
    border-radius: 28px;
    font-size: 7px;

    .ImgHaveInsurance {
      background: url(../../assets//images/have-insurance.png);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -8px;
      left: 0;
    }

    @media only screen and (max-width: $mobileBreakPoint) {
      top: 5px;
      right: 5px;
      padding: 5px;
      text-indent: 12px;
      .ImgHaveInsurance {
        top: -4px;
      }
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      top: 10px;
      right: 10px;
      padding: 5px 10px;
      text-indent: 6px;
    }
  }

  .Price {
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: $mobileBreakPoint) {
      $height: 30px;
      margin-top: -$height/2;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      $height: 50px;
      margin-top: -$height/2;
    }

    .ContentWrapper {
      background-color: $gold;

      transform: skewX(-25deg);
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: $mobileBreakPoint) {
        $height: 30px;
        height: $height;
        padding-right: 10px;
        padding-left: 10px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        $height: 50px;
        height: $height;
        padding-right: 20px;
        padding-left: 20px;
      }

      .Amount {
        @extend %fontExtraBold;
        color: white;
        display: inline-block;
        transform: skewX(25deg);

        @media only screen and (max-width: $mobileBreakPoint) {
          font-size: 14px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          font-size: 20px;
        }
      }

      .Currency {
        padding-left: 5px;
        color: white;
        display: inline-block;
        transform: skewX(25deg);
      }
    }
  }

  .TitleButtonGroup {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: $mobileBreakPoint) {
      min-height: 80px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      max-height: 150px;
    }

    .Title {
      @extend %fontMedium;
      text-align: center;

      @media only screen and (max-width: $mobileBreakPoint) {
        font-size: 12px;
        padding: 10px 5px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding: 15px;
        font-size: 18px;
        height: 70px;
      }
    }
  }

  .footer-content-course-card-wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    width: 100%;
    height: 30px;
    flex-grow: 50% 50%;
    justify-content: space-around;
    border-top: 1px solid #707070;
    padding-top: 5px;
    .item-footer-content-course-card-wrapper {
      display: flex;
      align-items: center;
      .icon {
        width: 23px;
        height: 23px;
        border-radius: 50%;
        color: $gold;
        margin-right: 6px;
        padding: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        color: #707070;
        font-size: 13px;
        font-family: 'Roboto';
        margin-bottom: 0px;
      }
    }
    .line-footer-content-course-card-wrapper {
      height: 100%;
      width: 1px;
      background-color: #707070;
    }
  }
  .PayContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding-bottom: 10px;
    }

    .PayNow {
      text-align: center;
      border: 1px solid $gold;
      @extend %fontSemiBold;
      color: white;
      background-color: $gold;
      cursor: pointer;
      @media only screen and (max-width: $mobileBreakPoint) {
        font-size: 10px;
        padding: 5px 10px;
        border-radius: 10px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        font-size: 14px;
        padding: 10px 25px;
        border-radius: 28px;
      }
    }
  }
}
