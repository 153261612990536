$rhap_bar-color: #b97a2a !default;

.audio-player-container {
  max-width: 307px;
  padding: 0;
  box-shadow: none;
  margin: auto;
  .rhap_progress-filled {
    background-color: #b97a2a;
  }
  .rhap_progress-indicator {
    display: none;
  }
  .rhap_progress-section {
    .rhap_time {
      font-size: 11px;
      color: #929292;
    }
  }
}