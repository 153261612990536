@import '../../../shared.scss';

.partners-list-wrap {
    border-top: 1px solid $brown;
    margin: 0 120px;
    margin-top: 65px;
    padding-top: 35px;
    #title-partner {
        width: 100%;
        text-align: center;
        color: $brown;
        font-size: 30px;
        font-weight: bold;
    }
    #partner-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        row-gap: 22px;
        position: relative;
        justify-content: center;
        column-gap: 6%;
        margin: 54px 0;
    }
}

@media only screen and (max-width: $smallMobileBreakPoint) {
    .partners-list-wrap {
        margin: 0 15px;
        margin-top: 65px;
        #partner-list-wrapper {
            column-gap: 3%;
            padding: 0px 15px;
        }
        
    }
}