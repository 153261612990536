// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

#rank-container {
  align-items: center;
  padding: 0 100px;
  @media only screen and (max-width: $smallMobileBreakPoint) {
    padding: 0 0px;
    margin-top: 43px;
    margin-bottom: 47px;
  }
  @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
    padding: 0px 0px;
    margin-top: 43px;
    margin-bottom: 47px;
  }

  .line {
    border: solid 1px;
    color: #707070;
  }
}