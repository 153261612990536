@import '../../shared.scss';
@import '../../assets/font/font.scss';

%paddingTitle {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 70px;
  }
}

.AccountDashboardPage {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-bottom: 20px;
  }
  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-bottom: 60px;
  }
  .Overview {
    @extend %paddingTitle;

    .Title {
      color: $darkblue;
      @extend %fontBold;
      @extend %fontHeader;

      border-bottom: 1px solid $lightgrey;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding-bottom: 5px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-bottom: 10px;
      }
    }

    .OverviewContainer {
      display: flex;

      @media only screen and (max-width: $mobileBreakPoint) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 40px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-top: 25px;
      }

      .MyRank {
        position: relative;
        @extend %fontBold;
        color: $brown;
        border: 1px solid $brown;
        white-space: nowrap;

        @media only screen and (max-width: $mobileBreakPoint) {
          font-size: 10px;
          text-align: center;
          padding: 10px 30px 10px 10px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          padding: 30px 60px 30px 10px;
          margin-right: 70px;
          margin-bottom: 50px;
          margin-top: 50px;
          font-size: 20px;
          text-align: right;
        }

        &.NoRank {
          padding: 30px !important;
        }

        .RankImg {
          background-color: white;
          position: absolute;

          @media only screen and (max-width: $mobileBreakPoint) {
            $sizeRank: 60px;
            width: $sizeRank;
            right: -$sizeRank/2;
            top: -$sizeRank/2 - 8px;
          }
          @media only screen and (min-width: $mobileBreakPoint + 1) {
            $sizeRank: 90px;
            width: $sizeRank;
            right: -$sizeRank/2;
            top: -$sizeRank/2 - 15px;
          }
        }
      }

      .Statistics {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        justify-content: space-evenly;

        @media only screen and (max-width: $mobileBreakPoint) {
          width: 100%;
          padding-top: 20px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          min-width: 60%;
        }

        .Money,
        .People {
          box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

          @media only screen and (max-width: $mobileBreakPoint) {
            padding: 10px 5px;
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            padding: 15px 20px;
          }
        }
        .Text {
          color: $darkblue;
          @media only screen and (max-width: $mobileBreakPoint) {
            padding-top: 5px;
            font-size: 8px;
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            white-space: nowrap;
            padding-top: 10px;
          }
        }

        .MoneyContainer {
          .Money {
            .Number {
              color: $brown;
              @extend %fontSemiBold;

              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 17px;
              }

              @media only screen and (min-width: $mobileBreakPoint + 1) {
                font-size: 33px;
              }
            }
          }
        }

        .PeopleContainer {
          .People {
            .Number {
              color: $blue;
              @extend %fontSemiBold;
              @media only screen and (max-width: $mobileBreakPoint) {
                font-size: 17px;
              }

              @media only screen and (min-width: $mobileBreakPoint + 1) {
                font-size: 33px;
              }
            }
          }
        }

        .TotalCommission,
        .TotalReferral {
          @media only screen and (max-width: $mobileBreakPoint) {
            margin-bottom: 15px;
          }

          @media only screen and (min-width: $mobileBreakPoint + 1) {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  .RevenueChart {
    @extend %paddingTitle;
    @media only screen and (max-width: $mobileBreakPoint) {
      overflow: scroll;
    }

    .Title {
      @extend %fontSemiBold;
      color: $lightblue;
      @extend %fontHeader;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding-bottom: 5px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-bottom: 15px;
      }
    }

    .Chart {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
      min-width: 500px;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding-top: 5px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding: 20px 20px 0 20px;
      }
    }
  }

  .RevenueTable {
    @extend %paddingTitle;
    overflow-x: auto;

    .Title {
      @extend %fontSemiBold;
      color: $lightblue;
      @extend %fontHeader;

      @media only screen and (max-width: $mobileBreakPoint) {
        padding-bottom: 5px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-bottom: 15px;
      }
    }

    .Table {
      width: 100%;
      text-align: center;
      margin: auto;
      border-collapse: collapse;
      .FirstCell {
        width: 16%;
      }
      .EqualCell {
        width: 7%;
      }

      @media only screen and (max-width: $mobileBreakPoint) {
        thead tr th {
          background-color: $gold;
          color: white;
          padding: 5px;
        }

        tbody tr td {
          padding: 5px;
          color: black;
          border-bottom: 1px solid $lightgrey;
        }
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        thead tr th {
          background-color: $gold;
          color: white;
          padding: 15px 0px;
        }

        tbody tr td {
          padding: 15px 0px;
          color: black;
          border-bottom: 1px solid $lightgrey;
        }
      }
    }
  }

  .Member {
    overflow-x: auto;

    @extend %paddingTitle;
    @media only screen and (max-width: $mobileBreakPoint) {
    }

    .Title {
      @extend %fontSemiBold;
      @extend %fontHeader;
      color: $lightblue;
      @media only screen and (max-width: $mobileBreakPoint) {
        padding-bottom: 5px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-bottom: 15px;
      }
    }

    .Status {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      div {
        @media only screen and (max-width: $mobileBreakPoint) {
          padding-bottom: 2px;
          margin-bottom: 10px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          margin-bottom: 20px;
          padding-bottom: 5px;
        }
        &.active {
          border-bottom: 3px solid $brown;
        }
        &:hover {
          cursor: pointer;
        }
      }

      @media only screen and (max-width: $mobileBreakPoint) {
        :first-child {
          margin-right: 30px;
        }
        font-size: 10px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        :first-child {
          margin-right: 100px;
        }
      }
    }

    .Table {
      width: 100%;
      text-align: center;
      margin: auto;
      border-collapse: collapse;

      @media only screen and (max-width: $mobileBreakPoint) {
        overflow-x: auto;

        thead tr th {
          background-color: $gold;
          color: white;
          padding: 5px;
          border: none;
        }
        tbody tr td {
          padding: 5px;
          color: black;
          border-bottom: 1px solid $lightgrey;
        }
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        thead tr th {
          background-color: $gold;
          color: white;
          padding: 15px 0px;
          border: none;
        }
        tbody tr td {
          padding: 15px 0px;
          color: black;
          border-bottom: 1px solid $lightgrey;
        }
      }
    }

    .text-center{
      text-align: center;
    }
  }
  .Text {
    text-align: center;
    font-size: 18px;
  }

  .level {
    margin-top: 10px;
    color: #3694fc;
    background-color: white;
    font-size: 25px;
    text-transform: uppercase;
  }

  .pagination {
    display: inline-block;
  }

  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }

  .success {
    img {
      width: 3%;
    }
    font-size: 17px;
    color: green;
  }

  .warning {
    img {
      width: 3%;
    }
    font-size: 17px;
    color: orange;
  }

  .danger {
    img {
      width: 3%;
    }
    font-size: 17px;
    color: red;
  }
}
