// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

#member-card-container {
  width: 207px;
  height: 374px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: $smallMobileBreakPoint) {
    margin: auto;
  }
  @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
    margin: auto;
  }

  #member-avatar {
    position: absolute;
  }

  #card-description {
    width: 100%;
    height: 304px;
    margin-top: 70px;
    background-image: url('../../../assets/images/cardbackgroud.png');
    text-align: center;
    padding: 81px 12px;

    #description-divider {
      border-top: solid 3px #FFFFFF;
    }

    #description-header {
      p {
        color: #D59E29;
        font-family: 'Poppins', sans-serif;
      }
      #greeting {
        font-size: 12px;
      }
      #full-name {
        font-size: 17px;
        font-weight: 600;
      }
      #short-name {
        font-size: 12px;
        font-style: italic;
      }
    }

    #description-footer {
      #explainer {
        color: #FFFFFF;
        font-size: 14px;

      }
      #score-text {
        color: #B97A2A;
        font-size: 30px;
        font-weight: 600;
      }
      #score-unit {
        color: #707070;
        font-size: 12px;
        font-style: italic;
        cursor: pointer;
      }
    }
  }
  .btn-swap-money{
    margin-top: 10px;
    color: #F70C1A;
    padding: 14px 40px;
    background-color: #D59E29;
    border-radius: 8px;
    width: 100%;
  }
}