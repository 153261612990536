@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-Light.ttf);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-ExtraLight.ttf);
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-Italic.ttf);
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-ExtraBold.ttf);
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Muli';
  src: url(./assets/font/Muli-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

:root {
  --var-lightgrey: #d6d6d6;
  --var-grey: #929292;
  --var-darkgrey: #707070;
  --var-brown: #b97a2a;
  --var-gold: #d59e29;
  --var-gold-50: rgba(213, 158, 41, 0.5);
  --var-darkblue: #424b5f;
  --var-blue: #3794fc;
  --var-lightblue: #67758d;
  --font-sans: 'Muli', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-sans);
}

p {
  font-family: var(--font-sans) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
