@import '../../shared.scss';

@media screen and (max-width: $smallMobileBreakPoint) {
  .social-icon {
    width: 20px;
  }
}

@media screen and (min-width: $mobileBreakPoint) {
  .footer-logo {
    img {
      max-width: 159px;
      max-height: 119px;
    }
  }
  .social-icon {
    width: 36px;
  }
}