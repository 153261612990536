@import "../../shared.scss";

.key-active{
  border: 1px solid #d59e29;
  color: white;
  background-color: #d59e29;
  cursor: pointer;
  display: flex;
  padding: 4px 25px;
  border-radius: 5px;
  margin-right: 20px;
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (max-width: '640px') {
  .key-active{
    font-size: 10px;
    width: max-content;
  }
  .download{
    width: max-content;
  }
  .lang-selector {
    .ant-select-selector {
      border: none !important;
      font-size: 8px !important;
    }
  }
  .lang-selector-item {
    font-size: 8px !important;
  }
}

@media only screen and (min-width: $mobileBreakPoint) {

  .logo-wrap {
    max-width: 172px !important;
    max-height: 129px !important;
  }
  .lang-selector {
    .ant-select-selector {
      border: none !important;
      font-size: 14px !important;
    }
  }
  .lang-selector-item {
    font-size: 14px !important;
  }
}