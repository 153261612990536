@import '../../shared.scss';
@import '../../assets/font/font.scss';

.WithdrawNotification {
  .deposit-info{
    font-size: 17px;

    b{
      color: red;
      font-size: 21px
    }
  }

  @media only screen and (max-width: $mobileBreakPoint) {
    padding: 20px 10px 50px 10px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 130px;
  }

  .Title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: $darkblue;
    padding-bottom: 5px;
    border-bottom: 1px solid $lightgrey;
    @extend %fontHeader;

    @media only screen and (max-width: $mobileBreakPoint) {
      margin-bottom: 20px;
      img {
        margin-left: 10px;
        margin-right: 10px;
        width: 20px;
      }
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      margin-bottom: 40px;
      img {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .Message {
    white-space: pre-line;
  }

  .BackToWallet {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: $brown;
    color: white;
    @extend %fontSemiBold;
    border-radius: 10px;
    cursor: pointer;

    @media only screen and (max-width: $mobileBreakPoint) {
      font-size: 12px;
      padding: 5px 10px;
      margin-top: 20px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      font-size: 16px;
      padding: 10px 20px;
      margin-top: 30px;
    }

    img {
      margin-right: 10px;

      @media only screen and (max-width: $mobileBreakPoint) {
        width: 20px;
      }
    }
  }
}
