// media query
$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

#redem-history-container {
  margin-top: 124px !important;
  margin-left: 69px !important;
  margin-bottom: 115px !important;

  @media only screen and (max-width: $smallMobileBreakPoint) {
    margin-left: 0px !important;
  }

  @media only screen and (min-width: $mobileBreakPoint) and (max-width: $inBetweenBreakpoint) {
    margin-left: 0px !important;
  }

  #container-header {
    color: #B97A2A;
    font-size: 20px;
    font-weight: bold;
  }

  .redem-history-table {
    .ant-table-thead {
      tr {
        th {
          color: #B97A2A !important;
          font-weight: bold !important;
          background: #D6D6D6;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
          color: #000000;
        }
      }
    }
  }

  #container-footer {
    float: right;
    width: 330px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 82px;
    @media only screen and (max-width: $smallMobileBreakPoint) {
      margin-right: 0px !important;
    }
    p {
      color: #B97A2A;
      font-weight: bold;
      font-size: 20px;
    }
    #total-score {
      font-size: 25px;
    }
  }
}