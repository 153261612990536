@import '../../shared.scss';
@import '../../assets/font/font.scss';

$paddingContainer: 15px 0;
$MobilePaddingContainer: 10px 0;
$paddingLine: 0 0 15px 0;
$mobilePaddingLine: 0 0 7px 0;

.CourseLevelComponentContainer {
  min-width: 300px;
}
.CourseName {
  text-align: center;
  color: $darkgrey;
  @extend %fontBold;
  @extend %fontHeader;

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    max-width: 1103px;
    margin: auto;
    padding-bottom: 15px;
  }
}
.CourseLevelContainer {
  @media only screen and (max-width: $mobileBreakPoint) {
    align-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 25px;
    margin-right: 25px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    max-width: 1103px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .CourseLevel {
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    box-shadow: 10px 3px 30px rgba(213, 158, 41, 0.16);

    @media only screen and (max-width: $mobileBreakPoint) {
      padding: 0 15px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 70%;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      margin-left: 15px;
      max-width: 362px;
      padding: 0 20px;
      width: 100%;
    }

    .Level_PriceContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @media only screen and (max-width: $mobileBreakPoint) {
        padding: $MobilePaddingContainer;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding: $paddingContainer;
      }

      .PriceContainerWrap {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }

      .Text {
        color: $brown;
        text-align: center;
        padding-bottom: 5px;
      }

      .Level {
        background-color: $gold;
        border-radius: 28px;
        color: white;
        @extend %fontBold;
        padding: 5px 15px;

        @media only screen and (max-width: $mobileBreakPoint) {
          font-size: 12px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
        }
      }
      .NumberContainer {
        // background-color: $gold;
        color: white;
        transform: skewX(-30deg);
        padding: 5px 10px;

        .PriceNumber {
          display: inline-block;
          transform: skewX(30deg);

          span {
            @extend %fontExtraBold;
            padding-right: 5px;
          }
        }
        @media only screen and (max-width: $mobileBreakPoint) {
          font-size: 14px;
          sup {
            font-size: 10px;
          }
        }
        @media only screen and (min-width: $mobileBreakPoint + 1) {
          font-size: 20px;

          sup {
            font-size: 12px;
          }
        }
      }
    }

    .AddInsurance {
      flex-direction: column;
      display: flex;
      .Text {
        width: 100%;
        color: #707070;
        @extend %fontSemiBold;
        @media only screen and (max-width: $mobileBreakPoint) {
          font-size: 14px;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          font-size: 16px;
        }
        .ant-checkbox-wrapper {
          margin-right: 5px;
          .ant-checkbox {
            .ant-checkbox-inner {
              border: 1px solid#d59e29;
              border-radius: 2px;
            }
          }
          .ant-checkbox.ant-checkbox-checked {
            &:after {
              border: none;
            }
            .ant-checkbox-inner {
              background-color: #d59e29;
            }
          }
        }
      }
      .FeeInsurance {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .NumberContainer {
        // background-color: #d59e29;
        color: white;
        transform: skewX(-30deg);
        padding: 5px 10px;
        margin-right: 10px;
        .PriceNumber {
          display: inline-block;
          transform: skewX(30deg);
          @extend %fontExtraBold;
          > span {
            padding-right: 5px;
          }
          .Currency {
            color: #fff;
          }
        }
      }
    }

    .PayNow {
      border-top: 1px solid #d6d6d6;
      border-bottom: 1px solid #d6d6d6;
      text-align: center;
      line-height: 1.5;
      @media only screen and (max-width: $mobileBreakPoint) {
        padding: $MobilePaddingContainer;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding: $paddingContainer;
      }

      .PayButton {
        color: white;
        @extend %fontBold;
        background-color: $gold;
        border-radius: 10px;
        padding: 10px 0;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .Quantity {
        @extend %fontBold;
      }
    }

    .Info {
      padding-top: 15px;
      .Container {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media only screen and (max-width: $mobileBreakPoint) {
          padding: $mobilePaddingLine;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          padding: $paddingLine;
        }

        img {
          padding-right: 10px;
        }
      }
      span {
        @extend %fontExtraBold;
        color: $grey;

        @media only screen and (max-width: $mobileBreakPoint) {
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  .InsurranceBox {
    margin-top: 20px;
    padding: 0;
    background: url(../../assets/images/background-insurrancebox.png);
    .TitleContainer {
      display: flex;
      border-bottom: 1px solid #d59e29;
      .PrivilegesTitle {
        width: 140px;
        height: 110px;
        background: #d59e29;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        > p {
          padding: 33px 20px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .BoxInsurranceTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        > p {
          text-transform: uppercase;
          color: #b97a2a;
        }
      }
    }
    .TitleFeeContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
    }
    .FeeBox {
      display: flex;
      justify-content: center;
      .Box {
        width: 138px;
        height: 40px;
        background-color: #d59e29;
        clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        > span {
          padding-bottom: 16px;
          padding-left: 4px;
        }
      }
    }
    hr {
      border-bottom: 0.5px solid #929292;
      margin: 0px 18px;
    }
    .ButtonBuyContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .BuyButton {
        background: #b97a2a;
        text-transform: uppercase;
        padding: 9px 40px;
        border-radius: 999px;
        color: #fff;
      }
    }
    .QuestionContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 30px 0;
      > p > i {
        color: #707070;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

// modal content
.wrapper__content {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .left {
    width: 28%;
    display: flex;
    flex-direction: column;
    .question {
      color: #707070;
      padding: 16px 16px;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #d59e29;
        border-radius: 30px;
      }
    }
  }
  .right {
    width: 70%;
    .answer__container {
      border: 1px solid #707070;
      padding: 18px 30px;
      max-height: 430px;
      overflow-y: auto;
    }
  }
}

.AudioBookContainer {
  width: 300px;
  margin-left: 15px;
  @media only screen and (max-width: $mobileBreakPoint) {
    width: 70%;
    margin: auto;
  }
}