@import '../../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .document-card-wrapper {
    width: 166px;
    height: 276px;
    margin-bottom: 31px;
    .cover-document-card-wrapper {
      width: 100%;
      height: 219px;
      position: relative;
      margin-bottom: 10px;
      .image-cover-document-card-wrapper {
        width: 100%;
        height: 100%;
      }
      .download-button-document-card-wrapper {
        display: none;
      }
    }
    .cover-document-card-wrapper:hover {
      .download-button-document-card-wrapper {
        display: block;
        padding: 0px;
        background: none;
        border: none;
        position: absolute;
        bottom: 0px;
        right: 0px;
        outline: none;
        width: 65px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .content-document-card-wrapper {
      width: 100%;
      p {
        font-size: 13px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  .document-card-wrapper {
    width: 18.056vw;
    height: 28.333vw;
    margin: auto;
    margin-bottom: 4.375vw;
    .cover-document-card-wrapper {
      width: 100%;
      height: 28.333vw;
      position: relative;
      margin-bottom: 1.042vw;
      .image-cover-document-card-wrapper {
        width: 100%;
        height: 100%;
      }
      .download-button-document-card-wrapper {
        display: none;
      }
    }
    .cover-document-card-wrapper:hover {
      .download-button-document-card-wrapper {
        padding: 0px;
        background: none;
        border: none;
        display: block;
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 65px;
        height: 60px;
        outline: none;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .content-document-card-wrapper {
      p {
        font-size: 1.042vw;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  .document-card-wrapper {
    width: 260px;
    height: 408px;
    margin-bottom: 63px;
    .cover-document-card-wrapper {
      width: 100%;
      height: 344px;
      position: relative;
      margin-bottom: 15px;
      .image-cover-document-card-wrapper {
        width: 100%;
        height: 100%;
      }
      .download-button-document-card-wrapper {
        display: none;
      }
    }
    .cover-document-card-wrapper:hover {
      .download-button-document-card-wrapper {
        padding: 0px;
        background: none;
        border: none;
        display: block;
        position: absolute;
        bottom: 0px;
        right: 0px;
        outline: none;
      }
    }
    .content-document-card-wrapper {
      p {
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: #000000;
        margin-bottom: 0px;
      }
    }
  }
}