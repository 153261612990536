@import '../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  #speaker-view-wrapper {
    width: 339px;
    margin: auto;
    margin-top: 27px;
    margin-bottom: 57px;
    /* SPEAKER SUMMANY INFORMATION COMPONENT */
    #summary-speaker-view-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $gold;
      padding-bottom: 24px;
      #avatar-summary-speaker-view-wrapper {
        width: 282px;
        height: 219px;
        margin-bottom: 23px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      #information-summary-speaker-view-wrapper {
        width: 100%;
        #header-information-summary-speaker-view-wrapper {
          font-size: 20px;
          color: $brown;
          font-family: 'Poppins', sans-serif;
          font-weight: bold;
          margin-bottom: 0px;
        }
        #body-information-summary-speaker-view-wrapper {
          font-size: 15px;
          color: $grey;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          margin-bottom: 24px;
        }
        #footer-information-summary-speaker-view-wrapper {
          font-size: 12px;
          color: #000000;
        }
      }
    }
    /* SPEAKER STUDY INFORMATION */
    #study-info-speaker-view-wrapper {
      width: 100%;
      padding: 23px 0px 24px 0px;
      border-bottom: 1px solid $gold;
      #title-study-info-speaker-view-wrapper {
        font-size: 20px;
        color: $gold;
        margin-bottom: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
      }
      .item-study-info-speaker-view-wrapper {
        color: #000000;
        font-size: 12px;
        margin-bottom: 30px;
        white-space: pre-line;
      }
    }
    #work-info-speaker-view-wrapper {
      width: 100%;
      margin-top: 24px;
      #title-work-info-speaker-view-wrapper {
        font-size: 15px;
        font-family: 'Poppins', sans-serif;
        color: $brown;
        margin-bottom: 72px;
      }
      #content-work-info-speaker-view-wrapper {
        padding-left: 13px;
        .ant-timeline-item {
          .ant-timeline-item-tail {
            border-left: 2px solid #707070;
          }
          .ant-timeline-item-head {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: $brown;
            top: -8px;
            left: -8px;
          }
          .ant-timeline-item-head-blue {
            color: $brown;
            border-color: $brown;
          }
          .ant-timeline-item-content {
            margin-left: 30px;
            .start-end {
              font-size: 14px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
              margin-bottom: 3px;
            }
            .work-place {
              font-size: 15px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .description {
              font-size: 14px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
            }
          }
        }
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  #speaker-view-wrapper {
    width: 79.65vw;
    min-width: 740px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 60px;
    padding: 20px 0px;
    /* SPEAKER SUMMANY INFORMATION COMPONENT */
    #summary-speaker-view-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 45px;
      border-bottom: 1px solid $gold;
      #avatar-summary-speaker-view-wrapper {
        width: 300px;
        height: 309px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      #information-summary-speaker-view-wrapper {
        width: 420px;
        #header-information-summary-speaker-view-wrapper {
          font-size: 16px;
          color: $brown;
          font-family: 'Poppins', sans-serif;
          font-weight: bold;
          margin-bottom: 7px;
        }
        #body-information-summary-speaker-view-wrapper {
          font-size: 14px;
          color: $grey;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          margin-bottom: 20px;
        }
        #footer-information-summary-speaker-view-wrapper {
          font-size: 12px;
          color: #000000;
        }
      }
    }
    /* SPEAKER STUDY INFORMATION */
    #study-info-speaker-view-wrapper {
      width: 100%;
      padding: 24px 0px 45px 0px;
      border-bottom: 1px solid $gold;
      #title-study-info-speaker-view-wrapper {
        font-size: 16px;
        color: $brown;
        margin-bottom: 24px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
      }
      .item-study-info-speaker-view-wrapper {
        color: #000000;
        font-size: 12px;
        margin-bottom: 10px;
        white-space: pre-line;
      }
    }
    /* SPEAKER WORK INFORMATION */
    #work-info-speaker-view-wrapper {
      width: 100%;
      margin-top: 24px;
      #title-work-info-speaker-view-wrapper {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        color: $brown;
        margin-bottom: 25px;
      }
      #content-work-info-speaker-view-wrapper {
        padding-left: 22px;
        .ant-timeline-item {
          .ant-timeline-item-tail {
            border-left: 2px solid #707070;
          }
          .ant-timeline-item-head {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $brown;
            top: -8px;
            left: -5px;
          }
          .ant-timeline-item-head-blue {
            color: $brown;
            border-color: $brown;
          }
          .ant-timeline-item-content {
            margin-left: 40px;
            .start-end {
              font-size: 12px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
              margin-bottom: 3px;
            }
            .work-place {
              font-size: 14px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .description {
              font-size: 12px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
            }
          }
        }
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  #speaker-view-wrapper {
    width: 1089px;
    max-width: 1089px;
    margin: auto;
    margin-top: 57px;
    margin-bottom: 116px;
    /* SPEAKER SUMMANY INFORMATION COMPONENT */
    #summary-speaker-view-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 45px;
      border-bottom: 1px solid $gold;
      #avatar-summary-speaker-view-wrapper {
        width: 398px;
        height: 309px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      #information-summary-speaker-view-wrapper {
        width: 623px;
        #header-information-summary-speaker-view-wrapper {
          font-size: 25px;
          color: $brown;
          font-family: 'Poppins', sans-serif;
          font-weight: bold;
          margin-bottom: 12px;
        }
        #body-information-summary-speaker-view-wrapper {
          font-size: 17px;
          color: $grey;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          margin-bottom: 44px;
        }
        #footer-information-summary-speaker-view-wrapper {
          font-size: 14px;
          color: #000000;
        }
      }
    }
    /* SPEAKER STUDY INFORMATION */
    #study-info-speaker-view-wrapper {
      width: 100%;
      padding: 24px 0px 45px 0px;
      border-bottom: 1px solid $gold;
      #title-study-info-speaker-view-wrapper {
        font-size: 25px;
        color: $brown;
        margin-bottom: 24px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
      }
      .item-study-info-speaker-view-wrapper {
        color: #000000;
        font-size: 14px;
        margin-bottom: 10px;
        white-space: pre-line;
      }
    }
    /* SPEAKER WORK INFORMATION */
    #work-info-speaker-view-wrapper {
      width: 100%;
      margin-top: 24px;
      #title-work-info-speaker-view-wrapper {
        font-size: 25px;
        font-family: 'Poppins', sans-serif;
        color: $brown;
        margin-bottom: 25px;
      }
      #content-work-info-speaker-view-wrapper {
        padding-left: 62px;
        .ant-timeline-item {
          .ant-timeline-item-tail {
            border-left: 2px solid #707070;
          }
          .ant-timeline-item-head {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            background-color: $brown;
            top: -8px;
            left: -8px;
          }
          .ant-timeline-item-head-blue {
            color: $brown;
            border-color: $brown;
          }
          .ant-timeline-item-content {
            margin-left: 40px;
            .start-end {
              font-size: 14px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
              margin-bottom: 3px;
            }
            .work-place {
              font-size: 15px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
              font-weight: bold;
              margin-bottom: 10px;
            }
            .description {
              font-size: 14px;
              color: #000000;
              font-family: 'Poppins', sans-serif;
            }
          }
        }
      }
    }
  }
}