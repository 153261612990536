@import '../../../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  .video-card-wrapper {
    width: 340px;
    height: 249px;
    .cover-video-card-wrapper {
      width: 100%;
      height: 192px;
      margin-bottom: 10px;
      .image-cover-video-card-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .content-video-card-wrapper {
      p {
        font-size: 13px;
        color: #000000;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 0px;
      }
    }
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  .video-card-wrapper {
    width: 24.444vw;
    height: 18.194vw;
    .cover-video-card-wrapper {
      width: 100%;
      height: 13.819vw;
      margin-bottom: 0.972vw;
      .image-cover-video-card-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .content-video-card-wrapper {
      p {
        font-size: 1.042vw;
        color: #000000;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 0px;
      }
    }
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  .video-card-wrapper {
    width: 352px;
    height: 262px;
    .cover-video-card-wrapper {
      width: 100%;
      height: 199px;
      margin-bottom: 14px;
      .image-cover-video-card-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .content-video-card-wrapper {
      p {
        font-size: 15px;
        color: #000000;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 0px;
      }
    }
  }
}