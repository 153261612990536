@import "../../shared.scss";
@import "../../assets/font/font.scss";


.AccountBreadcrumb {
  box-shadow: 0px 0px 26px rgba(228, 225, 225, 1);

  @media only screen and (max-width: $mobileBreakPoint) {
    font-size: 12px;
    margin-bottom: 20px;
    padding: 5px 10px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 5px;
    @extend %fontSemiBold;
    color: grey;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding-bottom: 2px;
    }
  }

  .ActiveTab {
    border-bottom: 1px solid $brown;
  }

  .Container {
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: $smallMobileBreakPoint) {
      height: 40px;
    }
    @media only screen and (min-width: $mobileBreakPoint) {
      height: 50px;
    }

    .LinkContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      text-transform: uppercase;
      height: 100%;

      @media only screen and (max-width: $smallMobileBreakPoint) {
        font-size: 8px;
      }

      @media only screen and (min-width: $mobileBreakPoint) {
        width: 100%;
        font-size: 14px;
      }
    }
    .AccountContainer {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 5px;

      @media only screen and (max-width: $smallMobileBreakPoint) {
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 0.375rem;
      }

      @media only screen and (max-width: $mobileBreakPoint) {
        flex-direction: row;
        align-items: baseline;
        width: 100%;
      }
      
      @media only screen and (min-width: $inBetweenBreakpoint) {
        flex-direction: row;
        align-items: baseline;
        width: 50%;
      }

      .Fullname {
        @extend %fontBold;
        padding-left: 5px;
        // @extend %fontHeader;
      }
    }
  }
  .profile-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    .dropdown-content {
      display: none;
      z-index: 999;
      min-width: max-content;
      position: absolute;
      transition: all 0.5s ease;
      font-size: 12px;

      @media only screen and (max-width: $smallMobileBreakPoint) {
        top: 21px;
        left: 42px;
      }

      @media only screen and (min-width: $mobileBreakPoint) {
        top: 31px;
        left: 64px;
      }

      @media only screen and (min-width: $inBetweenBreakpoint) {
        top: 50px;
        left: 64px;
      }

      .profile-item:hover {
        color: $gold !important;
      }
    }
    &:hover {
      .dropdown-content {
        display: block;
      }
    }

    img {
      @media only screen and (max-width: $mobileBreakPoint) {
        padding-left: 5px;
        width: 18px;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding-left: 10px;
      }
    }
  }
}
