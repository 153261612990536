@import "../../shared.scss";
@import "../../assets/font/font.scss";

.SendContactSuccess {
  text-align: center;
  margin-top: 100px;
  font-weight: 600;
  font-size: 1.2em;

  .GotoHome {
    width: 165px;
    background-color: #d59e28;
    padding: 10px;
    margin: 30px auto 0 auto;
    border-radius: 10px;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
}
