@import '../../shared.scss';


.account-course-wrapper {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-left: 7.19vw;
    padding-right: 7.24vw;
    .title-account-course-wrapper {
      background-color: #D6D6D6;
      font-size: 3.13vw;
      font-weight: bold;
      width: 100%;
      height: 6.25vw;
      text-align: center;
      padding-top: 0.63vw;
      color: #424B5F;
    }
    .ant-tabs {
      margin-top: 3.75vw;
      .ant-tabs-nav {
        width: 260px;
        margin: auto;
        margin-bottom: 3.75vw;
        .ant-tabs-nav-wrap {
          width: 100%;
          .ant-tabs-nav-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .ant-tabs-ink-bar {
              background-color: $gold;
            }
            .ant-tabs-tab {
              font-weight: bold;
              color: #424B5F;
              font-size: 3.13vw;
            }
            .ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $gold;
                font-size: 3.13vw;
                font-weight: bold;
              }
            }
          }
        }
      }
      .ant-result {
        height: 93.75vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  @media only screen and (min-width: $inBetweenBreakpoint) {
    padding-left: 7.19vw;
    padding-right: 7.24vw;
    .title-account-course-wrapper {
      background-color: #D6D6D6;
      font-size: 1.04vw;
      font-weight: bold;
      width: 100%;
      height: 2.97vw;
      text-align: center;
      padding-top: 0.63vw;
      color: #424B5F;
    }
    .ant-tabs {
      margin-top: 1.93vw;
      .ant-tabs-nav {
        width: 28.02vw;
        margin: auto;
        margin-bottom: 1.56vw;
        .ant-tabs-nav-wrap {
          width: 100%;
          .ant-tabs-nav-list {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .ant-tabs-ink-bar {
              background-color: $gold;
            }
            .ant-tabs-tab {
              font-weight: bold;
              color: #424B5F;
              font-size: 1.04vw;
            }
            .ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $gold;
                font-size: 1.04vw;
                font-weight: bold;
              }
            }
          }
        }
      }
      .CoursesContainer {
        display: flex;
        flex-direction: row;
      }
      .ant-result {
        height: 28.8vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
.CourseBlock {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-bottom: 130px;
    // padding-left: 150px;
    // padding-right: 150px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .CourseName {
    color: $darkblue;
    border-bottom: 1px solid $lightgrey;
    @extend %fontHeader;
    text-align: left;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding-bottom: 5px;
    }

    @media only screen and (min-width: $mobileBreakPoint + 1) {
      padding-bottom: 7px;
      margin-bottom: 20px;
    }
  }

  .CoursesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
