@import '../../shared.scss';
@import '../../assets/font/font.scss';

.MyProfile {
  @media only screen and (max-width: $mobileBreakPoint) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;

    .Avatar {
      .Photo {
        margin-bottom: 5px;
      }
    }

    .Data {
      padding-top: 5px;
      padding-bottom: 5px;
      margin-bottom: 10px;
    }
  }

  @media only screen and (min-width: $mobileBreakPoint + 1) {
    padding-bottom: 130px;

    .Avatar {
      font-size: 25px;
      .Photo {
        margin-top: 18px;
        margin-bottom: 14px;
      }
    }

    .Data {
      padding-top: 8px;
      padding-bottom: 13px;
      margin-bottom: 13px;
    }
  }

  .Title {
    color: $darkblue;
    padding-bottom: 5px;
    border-bottom: 1px solid $lightgrey;
    @extend %fontHeader;
  }
  .Profile {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (max-width: $mobileBreakPoint) {
      padding: 20px 0;
    }

    .EditIcon {
      position: absolute;

      &:hover {
        cursor: pointer;
      }
      @media only screen and (max-width: $mobileBreakPoint) {
        $size: 25px;
        width: $size;
        right: $size/2;
        top: $size/2;
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        $size: 50px;
        width: $size;
        right: $size/2;
        top: $size/2;
      }
    }

    .Avatar,
    .Data {
      @extend %fontBold;
      color: $grey;
    }

    .Avatar {
      text-align: center;

      .Photo {
        $size: 155px;
        width: $size;
        height: $size;
        border-radius: $size/2;
        border: 1px solid $lightgrey;
        display: flex;

        @media only screen and (max-width: $mobileBreakPoint) {
          $size: 80px;
          width: $size;
          height: $size;
          border-radius: $size/2;
        }

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          $size: 155px;
          width: $size;
          height: $size;
          border-radius: $size/2;
        }
      }
    }
    .GroupProfile {
      @media only screen and (max-width: $mobileBreakPoint) {
        padding-left: 30px;
        width: calc(100% - 30px);
      }

      @media only screen and (min-width: $mobileBreakPoint + 1) {
        padding: 90px;
        max-width: 600px;
      }

      .Data {
        word-break: break-word;
        border-bottom: 1px solid $lightgrey;

        @media only screen and (min-width: $mobileBreakPoint + 1) {
          div {
            min-height: 40px;
          }
        }
      }

      .Text {
        color: $lightgrey;
      }
    }
  }
}
