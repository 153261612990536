.container__item__insurance {
  display: flex;
  padding: 30px 15px;
  background: url(../../images//background-item.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 26px;
  border-left: 2px solid #d59e29;
  .label {
    color: #97a4ba;
    margin-bottom: 0.5em;
  }
  .insurance__name {
    width: 80%;
    .name {
      color: #b97a2a;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .money__back {
    width: 20%;
    .cash {
      color: #3794fc;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
