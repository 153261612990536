@import '../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  // DOCUMENT LIST WRAPPER
  #audio-book-list-wrapper {
    width: 100%;
    margin: auto;
    margin-bottom: 40px;
    .ant-spin-nested-loading {
      width: 100%;
      .ant-spin-container {
        width: 100%;
        .ant-row {
          width: 100%;
          justify-content: space-between;
          div {
            max-width: 43.75vw !important;
          }
        }
      }
    }
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }

  #detail_thumbnail {
    width: 300px;
    height: 361px;
    object-fit: cover;
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint - 1) {
  // DOCUMENT LIST WRAPPER
  #audio-book-list-wrapper {
    margin-bottom: 6.25vw;
    .ant-spin-nested-loading {
      width: 100%;
      .ant-spin-container {
        width: 100%;
        .ant-row {
          width: 100%;
          gap: 32px;
          div {
            max-width: 160px !important;
          }
        }
      }
    }
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }

  #detail_thumbnail {
    width: 300px;
    height: 361px;
    object-fit: cover;
  }
}

//1024//
@media only screen and (min-width: $inBetweenBreakpoint) {
  // DOCUMENT LIST WRAPPER
  #audio-book-list-wrapper {
    margin-bottom: 40px;
    .ant-spin-nested-loading {
      width: 100%;
      .ant-spin-container {
        width: 100%;
        .ant-row {
          width: 100%;
          gap: 16px;
          div {
            max-width: 220px !important;
          }
        }
      }
    }
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }
  
  #detail_thumbnail {
    width: 340px;
    height: 407px;
    object-fit: cover;
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  // DOCUMENT LIST WRAPPER
  #audio-book-list-wrapper {
    padding-top: 20px;
    margin-bottom: 60px;
    width: 100%;
    .ant-spin-nested-loading {
      width: 100%;
      .ant-spin-container {
        width: 100%;
        .ant-row {
          width: 100%;
          gap: 74px;
          div {
            max-width: 217px !important;
          }
        }
      }
    }
  }
  // PAGINGATION
  .ant-list-pagination {
    text-align: center;
    .ant-pagination {
      .ant-pagination-prev, .ant-pagination-next {
        color: #000000;
        line-height: 0px;
        font-weight: bold;
        .ant-pagination-item-link {
          border: none;
        }
      }
      .ant-pagination-item {
        background: none;
        color: #000000;
        border: none;
      }
      .ant-pagination-item-active {
        background-color: $gold;
        border-radius: 10px;
        color: #FFFFFF;
        outline: none;
      }
    }
  }

  #detail_thumbnail {
    width: 340px;
    height: 407px;
    object-fit: cover;
  }
}
