@import '../../../../../shared.scss';
.partner-component-logo {
    text-decoration: none;
    cursor: pointer;
    width: 26%;
    min-width: 250px;
    max-width: 350px;
   &>img {
        width: 100%;
        
   }
}

@media only screen and (max-width: $smallMobileBreakPoint) {
    .partner-component-logo {
        width: 30%;
        &>img {
            width: 100%;
        }
    }
}