$smallMobileBreakPoint: 600px;
$mobileBreakPoint: 768px;
$inBetweenBreakpoint: 1024px;

.custom-card {

  border: none;
  width: 356px;

  .ant-card-cover {
    height: 184px;

    .card-image-cover {
      height: 100%;
    }
  }

  @media only screen and (max-width: $smallMobileBreakPoint) {

    width: 317px;
  }
  .ant-card-body {
    padding: 0px;
    margin-top: 8px;

    .gift-icon-abso {
      position: absolute;
      top: 8px;
      right: 13px;
    }

    .ant-card-meta {

      .ant-card-meta-detail {

        height: 85px;

        .ant-card-meta-title {
          font-weight: bolder;
          font-size: 14px;
          width: 100%;
          overflow: none;
          white-space: normal;
          text-overflow: none;
        }

        .ant-card-meta-description {
          .card-footer-container {
            display: flex !important;
            justify-content: space-between;
          
            p {
              color: #B97A2A;
              font-size: 14px;
            }
          
            button {
              background-color: #D59E29;
              width: 92px;
              height: 27px;
              border-radius: 20px;
              color: #FFFFFF;
              font-weight: bold;
              border: none;
            }
          }
        }
      }
    }
  }
}