@import '../../../shared.scss';

:root {
  --wheel-font: 'Lato', 'Quicksand', sans-serif;
  --wheel-size: 300px;
  @media only screen and (max-width: 425px) {
    --wheel-size: 300px;
  }
  @media only screen and (min-width: 426px) and (max-width: 1024px) {
    --wheel-size: 300px;
  }
  --wheel-slice-spacing: 1px;
  --wheel-border-size: 5px;
  --wheel-color: #d59e29;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 1;
  --item-nb: 1;
  --selected-item: 1;
  --nb-turn: 7;
  --spinning-duration: 4s;
  --reset-duration: 0.25s;
}

.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 3px;
  margin: auto;
  // background-color: #244157;
  border: solid #C25F08 5px;
  border-radius: 50%;
  user-select: none;
  z-index: 1;
}

.wheel-container::before,
.wheel-container::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: solid transparent 20px;
  border-left-width: 0;
}

.wheel-container::before {
  right: 0px;
  border-right-color: var(--wheel-color);
}

.wheel-container::after {
  right: -5px;
  border-right-color: var(--neutral-color);
}

/* Roue */
.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid #C25F08 var(--wheel-border-size);
  background-color: #D59E29;
  // transition: transform var(--reset-duration);
  transform: rotate(0deg);
  cursor: pointer;
}

.wheel.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(
    calc(
      var(--nb-turn) * 360deg +
        (-360deg * var(--selected-item) / var(--nb-item, 1))
    )
  );
}

/* Centre de la roue = rond blanc au centre */
.wheel::after {
  display: block;
  position: absolute;
  content: '';
  background-color: var(--neutral-color);
  width: 25px;
  height: 25px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.wheel-item {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%)
    rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
  color: var(--neutral-color);
  text-align: right;
  padding: 0 5px 0 40px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 414px) {
    font-size: 9px;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 12px;
  }
  font-size: 12px;
  img {
    width: 60px;
    height: 60px;
  }
}

.wheel-item:before {
  content: ' ';
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;

  /* 
    Largeur du triangle
    Je ne sais pas pourquoi j'ai besoin d'ajouter 
      " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
    mais globalement ca marche a peu près bien 
    TODO: A étudier 
  */
  --slice-max-width: calc(
    var(--PI) * var(--wheel-size) + var(--wheel-size) / 2
  );
  --slice-width: calc(
    (var(--slice-max-width) / var(--nb-item, 1)) -
      var(--wheel-slice-spacing)
  );
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
  border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}

.event_info__container {
  background-color: white;
  width: 24.2vw !important;
  height: 38.4vh !important;
  @media only screen and (max-width: 425px) {
    font-size: 10px;
    width: 100vw !important;
  }
  @media only screen and (min-width: 426px) and (max-width: 768px) {
    font-size: 12px;
  }
}

.time_event_header_container {
  width: 21.1vw !important;
  height: 10.2vh !important;
  background-color: white;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.event__container_text {
  @media only screen and (max-width: 414px) {
    font-size: 10px;
    position: absolute;
    top: -70px;
    padding-bottom: 5px;
    border-bottom: #707070 dashed 1px;
    margin-left: 5px;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 12px;
  }
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  margin: 0 0 5.86vh 0;
  padding: 0 0 0 0;
  color: #000000;
}

.event_container_body {
  background-color: white;
  padding-top: 12;
  padding-left: 16;
  @media only screen and (max-width: 414px) {
    margin-top: 15px;
    margin-left: 5px;
  }
}

// .event_info_footer {
//   // border: black solid 1px;
//   background-color: white;
//   align-items: center;
//   display: flex;
//   justify-content: center;
// }

.tag__info {
  @media only screen and (max-width: 414px) {
    font-size: 16px;
    width: 200px !important;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 22px;
    width: 200px !important;
  }
  margin: auto;
  width: 400px;
  height: 5.69vh !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: $gold;
}

.tag_turn__info {
  @media only screen and (max-width: 414px) {
    font-size: 16px;
    width: 20vw !important;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 22px;
    width: 14vw !important;
  }
  width: 8.34vw !important;
  height: 5.69vh !important;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.container___text {
  @media only screen and (max-width: 414px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 12px;
  }
  font-family: 'Poppins', sans-serif;
  color: $lightgrey;
}

.col__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: #707070 dashed 1px;
}

.button {
  @media only screen and (max-width: 414px) {
    font-size: 10px;
    width: 40vw !important;
    margin: auto;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 12px;
    width: 22vw !important;
  }
  width: 20.15vw !important;
  height: 4.45vh !important;
  background-color: #b97a2a;
  border-radius: 0.73vw;
  color: white;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.modal__button {
  @media only screen and (max-width: 414px) {
    font-size: 10px;
    width: 40vw !important;
    height: 6.45vh !important;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 12px;
  }
  white-space: normal;
  word-wrap: break-word;
  width: 100%;
  height: 40px !important;
  background-color: #b97a2a;
  border-radius: 0.73vw;
  color: white;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.modal__button_next_round {
  @media only screen and (max-width: 414px) {
    font-size: 10px;
    width: 40vw !important;
    height: 6.45vh !important;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 12px;
  }
  white-space: normal;
  word-wrap: break-word;
  width: 180px;
  height: 40px !important;
  background-color: #b97a2a;
  border-radius: 0.73vw;
  color: white;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.button_label {
  @media only screen and (max-width: 414px) {
    font-size: 10px;
  }
  @media only screen and (min-width: 415px) and (max-width: 768px) {
    font-size: 12px;
  }
  color: #ffffff;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.item__container {
  background-color: white;
  height: 300px !important;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  padding-top: 16px;
  div {
    margin: auto;
    width: 228px;
    height: 228px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  #logo-image {
    margin: auto;
    width: 300px;
    img {
      width: 100%;
    }
  }
}

.content__container {
  background-color: $gold;
  padding-top: 56px;
  padding-bottom: 56px;
  border-bottom: #ffffff solid 1px;
  .ant-row {
    .ant-col {
      text-align: center;
    }
  }
}

.footer__container {
  background-color: $gold;
  display: flex;
  align-items: center;
  justify-content: center;
  .button-footer-wrap {
    text-align: center;
    p {
      margin-bottom: 16px;
      color: #ffffff;
    }
  }
  .ant-form {
    .ant-form-item {
      input {
        background-color: $lightgrey;
        border-radius: 10px;
        color: #ffffff;
        font-weight: bold;
      }
      input::placeholder {
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
}

.modal_card_container {
  width: 58.2vw !important;
  height: 41.3vh !important;
  @media only screen and (max-width: 425px) {
    width: 90.2vw !important;
  }
}

.content_heading {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 20px 0;
  font-family: 'Poppins', sans-serif;
  margin: auto;
}



.game_turn_container {
  width: 13.54vw !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 7.24vw;
  @media only screen and (max-width: 414px) {
    margin-left: 0;
  }
}

.modal_card_container {
  .ant-modal-body {
    padding: 0px;
  }
}

.footer-logo-wrap {
  text-align: center;
  background-color: #ffffff;
  img {
    width: 120px;
  }
}

// .ant-layout {
//   // background-color: $gold;
// }

#form-mobile-title {
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
}

@media screen and (min-width: $mobileBreakPoint + 1) {
  #start-button {
    position: absolute;
    height: 58px;
    width: 58px;
    border-radius: 50%;
    background-color: #C25F08;
    top: 127px;
    left: 129px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: 'Poppins' sans-serif;
    color: #FFFFFF;
  }
  .main-container-wrap {
    width: 100%;
    max-width: 1085px;
    height: 417px;
    background-color: $gold;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin: auto !important;
    .col-main-container-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;
      #content-col-main-container-wrap {
        p {
          color: #FFFFFF;
          font-size: 50px;
          width: 100%;
          text-align: right;
        }
      }
      #lucky-spinner-footer {
        position: absolute;
        bottom: 0px;
        z-index: 0;
      }
    }
  }
  #turn_amount_info_wrap {
    display: flex;
    border: 0.5px solid #c4c4c4;
    border-radius: 13px;
    width: 100%;
    height: 36px;
    max-width: 1085px;
    margin: auto;
    margin-bottom: 16px;
    align-items: center;
    padding: 0px 10px;
    box-shadow: 1px 1px rgba($color: #c4c4c4, $alpha: 0.5);
    justify-content: space-between;
    p {
      font-family: 'Muli-SemiBold';
      color: $grey;
      margin-right: 10px;
      font-size: 16px;
    }
    #turn_amount_wrap {
      display: flex;
    }
    #time_event_wrap {
      display: flex;
    }
  }
}

@media screen and (max-width: $mobileBreakPoint) {
  #start-button {
    position: absolute;
    height: 43px;
    width: 43px;
    border-radius: 50%;
    background-color: #C25F08;
    top: 135px;
    left: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-family: 'Poppins' sans-serif;
    color: #FFFFFF;
  }
  .main-container-wrap {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
    background-color: $gold;
    height: auto;
    padding-top: 16px;
    .col-main-container-wrap {
      height: 100%;
      position: relative;
      #lucky-spinner-footer {
        position: absolute;
        bottom: -4.706vw;
        z-index: 0;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    #mobile-col-main-container-wrap {
      padding-top: 12px;
      #content-col-main-container-wrap {
        p {
          color: #FFFFFF;
          font-size: 30px;
          width: 100%;
          text-align: right;
        }
        #image-col-content-main-container-wrap {
          width: 210px;
          height: 144px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  .item__container {
    padding: 16px 0px 0px 0px;
  }
  #turn_amount_info_wrap {
    display: flex;
    flex-direction: column;
    border: 0.5px solid #c4c4c4;
    width: 100%;
    height: 130px;
    margin: auto;
    margin-bottom: 16px;
    align-items: center;
    padding: 10px 10px;
    box-shadow: 1px 1px rgba($color: #c4c4c4, $alpha: 0.5);
    justify-content: space-between;
    p {
      font-family: 'Muli-SemiBold';
      color: $grey;
      margin-right: 10px;
      font-size: 16px;
    }
    #turn_amount_wrap {
      display: flex;
      flex-direction: column;
    }
    #time_event_wrap {
      display: flex;
    }
  }
}

.layout_result {
  background-color: $gold;
}