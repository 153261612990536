@import '../../../shared.scss';

//MOBILE//
@media only screen and (max-width: $smallMobileBreakPoint) {
  #document-tabs-wrapper {
    // TAB NAVIGATION //
    .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-nav {
      margin-bottom: 0px;
      .ant-tabs-nav-wrap {
        padding: 16px 0px;
        .ant-tabs-nav-list {
          justify-content: center;
          .ant-tabs-ink-bar {
            width: 125px !important;
          }
          .ant-tabs-tab {
            width: 125px !important;
            justify-content: center;
            margin-right: 0px;
            padding: 7px 0px;
            .ant-tabs-tab-btn {
              font-size: 15px;
              font-family: 'Poppins', sans-serif;
              font-weight: bold;
            }
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $gold;
            }
          }
        }
      }
    }
    //--------------------//
  }
}

//TABLET//
@media only screen and (min-width: $smallMobileBreakPoint + 1) and (max-width: $inBetweenBreakpoint) {
  #document-tabs-wrapper {
    // TAB NAVIGATION //
    .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-nav {
      margin-bottom: 0px;
      .ant-tabs-nav-wrap {
        padding: 1.389vw 0px;
        .ant-tabs-nav-list {
          justify-content: center;
          .ant-tabs-ink-bar {
            width: 15vw !important;
          }
          .ant-tabs-tab {
            width: 15vw !important;
            justify-content: center;
            margin-right: 0px;
            .ant-tabs-tab-btn {
              font-size: 1.389vw;
              font-family: 'Poppins', sans-serif;
              font-weight: bold;
            }
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $gold;
            }
          }
        }
      }
    }
    //--------------------//
  }
}

//DESKTOP//
@media only screen and (min-width: $inBetweenBreakpoint + 1) {
  #document-tabs-wrapper {
    // TAB NAVIGATION //
    .ant-tabs-nav::before {
      border-bottom: none;
    }
    .ant-tabs-nav {
      margin-bottom: 0px;
      .ant-tabs-nav-wrap {
        padding: 20px 0px;
        .ant-tabs-nav-list {
          justify-content: center;
          .ant-tabs-ink-bar {
            width: 216px !important;
          }
          .ant-tabs-tab {
            width: 216px !important;
            justify-content: center;
            margin-right: 0px;
            .ant-tabs-tab-btn {
              font-size: 20px;
              font-family: 'Poppins', sans-serif;
              font-weight: bold;
            }
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: $gold;
            }
          }
        }
      }
    }
    //--------------------//
  }
}
